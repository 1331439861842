import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  lan: "en",
};

const translationSlice = createSlice({
  name: "TRANSLATION",
  initialState,
  reducers: {
    setLanguage: (state, action) => {
      state.lan = action.payload;
    },
  },
});

export const tranlationAction = translationSlice.actions;

export default translationSlice;
