import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiRequest } from "../../util/request";

export const fetchEvents = createAsyncThunk(
  "events/fetchEvents",
  async ({ page, sort, title }) => {
    return apiRequest
      .get(`/event_api/get_event?page=${page}&sort=${sort}&title=${title}`, {
        headers: {
          "Content-Type": "application/json",
          get_event_api: process.env.REACT_APP_GET_EVENT_API,
        },
      })
      .then((res) => res.data)
      .catch((error) => {
        if (error.response.status === 429) {
          window.location.href = "/error/request";
          throw error;
        }
      });
  }
);
const initialState = {
  events: [],
  loading: false,
  fullfilled: false,
  error: false,
};

const eventSlice = createSlice({
  name: "EVENTS",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchEvents.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchEvents.fulfilled, (state, action) => {
      state.loading = false;
      state.fullfilled = true;
      state.events = action.payload;
      state.error = false;
    });
    builder.addCase(fetchEvents.rejected, (state) => {
      state.loading = false;
      state.fullfilled = false;
      state.error = true;
    });
  },
});

export const menuAction = eventSlice.actions;

export default eventSlice;
