import React from "react";
import { useSelector } from "react-redux";

function HomeBookDate({ service }) {
  const serviceState = useSelector((state) => state?.services);
  const languageState = useSelector((state) => state?.lan);
  const findService = serviceState?.services?.find(
    (service1) => service1?._id === service
  );
  return (
    <div className="w-[50%] border border-gray-300 rounded-[20px] flex flex-col  items-start  gap-[10px] max-md2:w-[100%]">
      <div className="m-4 font-bold service-header text-[30px] max-lg2:text-[20px] text-[#4DB27D]">
        {languageState?.lan === "en" && <span>Selected Service</span>}
        {languageState?.lan === "amh" && <span>የመረጡት አገልግሎት</span>}
      </div>
      <div className="w-[90%] mx-auto">
        <div className="flex flex-col gap-[10px] text-[14px] px-4">
          <div className="text-[18px] font-bold service-header max-sm1:text-[16px]">
            {languageState?.lan === "en" && service !== "" && (
              <span>Menu Type</span>
            )}
            {languageState?.lan === "amh" && service !== "" && (
              <span>የምግብ ዓይነት</span>
            )}
          </div>
          <div className="text-[16px] leading-[30px] flex flex-col gap-[10px] text-gray-500 max-sm1:text-[14px]">
            {languageState?.lan === "en" && (
              <span> {findService?.menu_en}</span>
            )}
            {languageState?.lan === "amh" && (
              <span> {findService?.menu_am}</span>
            )}
          </div>
        </div>
      </div>
      {service === "" && (
        <div className="w-[90%] mx-auto">
          {languageState?.lan === "en" && (
            <span className="text-[18dpx] max-lg2:text-[16px] text-gray-500">
              You haven't selected any service.
            </span>
          )}

          {languageState?.lan === "amh" && (
            <span className="text-[20px] max-lg2:text-[16px] text-gray-500">
              ምንም አይነት አገልግሎት አልመረጡም
            </span>
          )}
        </div>
      )}

      {(findService?.additional_package_en ||
        findService?.additional_package_am) && (
        <div className="w-[90%] my-[20px] mx-auto  flex flex-col gap-[10px] px-4  text-[14px]">
          <div className="text-[20px] service-header font-bold max-sm1:text-[16px]">
            {languageState?.lan === "en" && <span>Additional Services</span>}
            {languageState?.lan === "amh" && <span>ተጨማሪ አገልግሎቶች</span>}
          </div>
          <div className="text-[16px] flex flex-col gap-[10px] text-gray-500 max-sm1:text-[14px]">
            {languageState?.lan === "en" && (
              <span>{findService?.additional_package_en}</span>
            )}
            {languageState?.lan === "amh" && (
              <span>{findService?.additional_package_am}</span>
            )}
          </div>
        </div>
      )}
      {(findService?.package_policy_en || findService?.package_policy_am) && (
        <div className="w-[90%] my-[20px] mx-auto  flex flex-col gap-[10px] px-4">
          <div className="text-[20px] service-header font-bold max-sm1:text-[16px]">
            {languageState?.lan === "en" && <span>Policy</span>}
            {languageState?.lan === "amh" && <span>ፖሊሲ</span>}
          </div>
          <div className="text-[16px] leading-[30px] flex flex-col gap-[10px] text-gray-500 max-sm1:text-[14px]">
            {languageState?.lan === "en" && (
              <span>{findService?.package_policy_en}</span>
            )}
            {languageState?.lan === "amh" && (
              <span>{findService?.package_policy_am}</span>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default HomeBookDate;
