import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchEvents } from "../REDUX/slices/eventSlice";
import { useNavigate } from "react-router-dom";
import { useDebounce } from "use-debounce";
import Error from "./Error";
import { IoIosArrowBack, IoIosArrowForward, IoIosSearch } from "react-icons/io";

function EventList() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const eventState = useSelector((state) => state?.events);
  const languageState = useSelector((state) => state?.lan);
  const [pageNum, setPageNum] = useState(1);
  const [sortingNum, setSortingNum] = useState(-1);
  const [eventName, setEventName] = useState("");
  const [debouncedTitle] = useDebounce(eventName, 500);

  useEffect(() => {
    dispatch(
      fetchEvents({
        page: pageNum,
        sort: sortingNum,
        title: debouncedTitle,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNum, sortingNum, debouncedTitle]);

  const handlePrevious = () => {
    if (pageNum <= 1) {
      setPageNum(1);
    } else {
      setPageNum(pageNum - 1);
    }
  };

  const handleNext = () => {
    if (pageNum >= eventState?.events?.totalPages) {
      setPageNum(eventState?.events?.totalPages);
    } else {
      setPageNum(pageNum + 1);
    }
  };

  if (eventState?.error) return <Error name="event" height="300px" />;
  return (
    <div className="w-[100%] min-h-[500px] my-[30px]">
      <div className="w-[70%] mx-auto my-[30px]  flex justify-between items-center max-lg2:w-[90%] max-md1:flex-col max-md1:gap-[20px]">
        <div className="w-[50%] bg-white flex px-4 items-center gap-[10px] border border-gray-300 rounded-[5px] max-md1:w-[100%]">
          <IoIosSearch className="text-[24px] text-gray-500 max-lg2:text-[20px]" />
          <input
            type="text"
            placeholder="Search by event title / በክስተቱ ርዕስ ይፈልጉ"
            onChange={(e) => setEventName(e?.target?.value)}
            className="py-3 flex-1 bg-transparent outline-none max-lg2:text-[15px]"
          />
        </div>

        {eventState?.events?.events?.length > 0 &&
          eventState?.events?.totalPages && (
            <div className="w-[50%] mx-auto flex justify-end items-center  gap-5 max-md1:w-[100%] max-md1:justify-start">
              <div className="flex items-center gap-[20px] justify-end w-[100%]">
                <button
                  onClick={handlePrevious}
                  className="mx-1 w-[30px] h-[30px] flex justify-center items-center cursor-pointer text-white border border-[#039674] bg-[#039674] rounded-[50%] hover:bg-transparent hover:text-[#039674] transition duration-500 max-[400px]:px-2 outline-none"
                >
                  <IoIosArrowBack />
                </button>
                <span className="text-gray-600 font-semibold">
                  {eventState?.events?.currentPage} of{" "}
                  {eventState?.events?.totalPages}
                </span>

                <button
                  onClick={handleNext}
                  className={
                    "mx-1 w-[30px] h-[30px] flex justify-center items-center cursor-pointer text-white border border-[#039674] bg-[#039674] rounded-[50%] hover:bg-transparent hover:text-[#039674] transition duration-500 max-[400px]:px-2 outline-none"
                  }
                >
                  <IoIosArrowForward />
                </button>

                <div className="flex items-center gap-2">
                  <select
                    value={sortingNum}
                    onChange={(e) => setSortingNum(e?.target?.value)}
                    className="py-2 px-4 border border-gray-300 rounded-[5px] outline-none text-[14px] font-medium"
                  >
                    {languageState?.lan === "en" && (
                      <option value={-1}>Latest</option>
                    )}
                    {languageState?.lan === "en" && (
                      <option value={1}>Oldest</option>
                    )}
                    {languageState?.lan === "amh" && (
                      <option value={-1}>የቅርብ ጊዜ</option>
                    )}
                    {languageState?.lan === "amh" && (
                      <option value={1}>የቆዩ</option>
                    )}
                  </select>
                </div>
              </div>
            </div>
          )}
      </div>

      <div className="w-[70%] mx-auto flex flex-col  gap-4 max-lg2:w-[90%]">
        {eventState?.events?.events?.length !== 0 &&
          eventState?.events?.events?.map((event, index) => {
            return (
              <div
                key={index}
                onClick={() => navigate(`/events/more_info/${event?._id}`)}
                className="p-2 bg-white  rounded-[10px] cursor-pointer border border-gray-300"
              >
                <div className="w-[90%] mx-auto py-2 flex gap-[20px] max-md2:flex-col">
                  <div className="w-[30%] h-[300px] max-lg2:w-[40%] max-md2:w-[100%] max-md2:h-[400px] max-sm1:h-[300px]">
                    <img
                      src={`${process.env.REACT_APP_BACKEND_IMAGE_URL}/EventImages/${event?.thumbnail}`}
                      className="w-[100%] h-[100%] object-cover rounded-[10px]"
                      alt=""
                    />
                  </div>
                  <div className="w-[70%] flex flex-col gap-[20px] max-md2:w-[100%]">
                    {languageState?.lan === "en" && (
                      <div className="my-[10px] flex justify-between items-center gap-[20px]">
                        <span className="font-bold capitalize text-[#039674] text-[24px]">
                          {event?.title_en}
                        </span>
                      </div>
                    )}
                    {languageState?.lan === "en" && (
                      <div className="flex flex-col gap-[20px] text-[18px] text-gray-500 leading-[30px]">
                        <span>{event?.description_en}</span>
                      </div>
                    )}

                    {languageState?.lan === "amh" && (
                      <div className="my-[10px] flex justify-between items-center gap-[20px]">
                        <span className="font-bold capitalize text-[#039674] text-[24px]">
                          {event?.title_am}
                        </span>
                      </div>
                    )}
                    {languageState?.lan === "amh" && (
                      <div className="flex flex-col gap-[20px] text-[18px] text-gray-500 leading-[30px]">
                        <span>{event?.description_am}</span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        {eventState?.events?.events?.length === 0 && (
          <div className="min-h-[500px] flex flex-col justify-center items-center gap-[10px]">
            {languageState?.lan === "en" && (
              <span className="text-[30px] text-[#4DB27D] font-bold service-header">
                No result found!
              </span>
            )}
            {languageState?.lan === "amh" && (
              <span className="text-[30px] text-[#4DB27D] font-bold service-header">
                ምንም የተለቀቁ ክስተቶች የሉም
              </span>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default EventList;
