import { IoPersonCircle } from "react-icons/io5";
import { MdEmail } from "react-icons/md";
import { FaPeopleGroup, FaPhone, FaRegClock } from "react-icons/fa6";
import {
  BsFillCalendarDateFill,
  BsFillTelephonePlusFill,
} from "react-icons/bs";
import { BiSolidFoodMenu } from "react-icons/bi";
import HomeBookDate from "./HomeBookDate";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchServices } from "../REDUX/slices/servicesSlice";
import { ToastContainer, toast } from "react-toastify";
import { TbCategoryFilled } from "react-icons/tb";
import "react-toastify/dist/ReactToastify.css";
import { apiRequest } from "../util/request";
import Loading from "./Loading";
import ConfirmReservation from "../pages/ConfirmReservation";
const today = new Date().toISOString().split("T")[0];

function BookADate() {
  const dispatch = useDispatch();
  const serviceList = useSelector((state) => state?.services);
  const languageState = useSelector((state) => state?.lan);
  const [fullName, setFullname] = useState("");
  const [category, setCategory] = useState("individual");
  const [email, setEmail] = useState("");
  const [primaryNumber, setPrimaryNumber] = useState("");
  const [secondaryNumber, setSecondaryNumber] = useState("");
  const [service, setService] = useState("");
  const [guests, setGuests] = useState("");
  const [reservationDate, setReservationDate] = useState("");
  const [reservationTime, setReservatonTime] = useState("");
  const [loading, setLoading] = useState("");
  const [confirmationModal, setConfirmationModal] = useState(false);

  useEffect(() => {
    dispatch(fetchServices());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const findActiveServices = serviceList?.services?.filter(
    (service) => service?.status === "active"
  );

  const createReservation = async () => {
    try {
      setLoading(true);
      await apiRequest
        .post(
          "/reservation_api/create_reservation",
          {
            category,
            name: fullName,
            email,
            primary_phone: primaryNumber,
            secondary_phone: secondaryNumber,
            // subject,
            package: service,
            num_of_ppls: guests,
            reservation_date: reservationDate,
            reservation_time: reservationTime,
          },
          {
            headers: {
              "Content-Type": "application/json",
              get_create_reservation_api:
                process.env.REACT_APP_GET_CREATE_RESERVATION_API,
            },
          }
        )
        .then(() => {
          setLoading(false);

          setFullname("");
          setCategory("");
          setEmail("");
          setPrimaryNumber("");
          setSecondaryNumber("");
          setService("");
          setGuests("");
          setReservationDate("");
          setReservatonTime("");
          setConfirmationModal(true);
        })
        .catch((error) => {
          setLoading(false);

          setConfirmationModal(false);
          toast.error(error?.response?.data?.Message);
        });
    } catch (error) {
      setLoading(false);
      toast.error("An error occured: please try again later!");
    }
  };
  return (
    <div
      id="book"
      className="bgimg w-[100%] min-h-[100vh] relative max-lg2:min-h-[70vh]"
    >
      <ToastContainer theme="light" />
      <div className="w-[100%] absolute top-0 left-0 h-[100%] bg-black opacity-70" />
      <div className="w-[80%] mx-auto flex flex-col gap-[30px] max-lg:w-[90%]">
        <div className="w-[100%] mt-[30px] z-[100] flex justify-center items-center gap-[10px]">
          {languageState?.lan === "en" && (
            <span className="service-header  max-lg2:text-[30px] text-[50px] text-white cursor-pointer">
              Book a
            </span>
          )}
          {languageState?.lan === "en" && (
            <span className="service-header max-lg2:text-[30px] text-[50px] text-[#4DB27D]">
              Service
            </span>
          )}
          {languageState?.lan === "amh" && (
            <span className="service-header text-[30px] text-white cursor-pointer">
              ቦታ
            </span>
          )}
          {languageState?.lan === "amh" && (
            <span className="service-header text-[30px] text-[#4DB27D]">
              ያስይዙ
            </span>
          )}
        </div>
        <div className="bg-white  w-[100%] rounded-[10px] p-4 mb-[30px] mx-auto z-[100] max-lg1:w-[100%]">
          <div className="flex  gap-[20px] max-lg2:w-[100%] max-md2:flex-col">
            <div className="w-[50%] flex flex-col  justify-between items-center gap-[20px] mt-[10px] max-md2:w-[100%]">
              <div className="w-[80%] border border-gray-200 rounded-[5px] p-2 flex items-center gap-[10px] max-md2:w-[100%]">
                <TbCategoryFilled className="text-[30px] text-gray-500" />
                <select
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                  className="flex-1 p-2 max-lg2:text-[14px] outline-none bg-transparent"
                >
                  <option value={""}></option>
                  {languageState?.lan === "en" && (
                    <option value={"individual"}>Individual</option>
                  )}
                  {languageState?.lan === "amh" && (
                    <option value={"individual"}>ግለሰብ</option>
                  )}

                  {languageState?.lan === "en" && (
                    <option value={"organization"}>Organization</option>
                  )}

                  {languageState?.lan === "amh" && (
                    <option value={"organization"}>ድርጅት</option>
                  )}
                </select>
              </div>
              <div className="w-[80%] border border-gray-200 rounded-[5px] p-2 flex items-center gap-[10px] max-md2:w-[100%]">
                <IoPersonCircle className="text-[30px] text-gray-500" />
                <input
                  type="text"
                  placeholder="Full Name / ሙሉ ስም"
                  value={fullName}
                  onChange={(e) => setFullname(e?.target?.value)}
                  className="flex-1 p-2 text-[18px]  max-lg2:text-[14px] outline-none"
                />
              </div>

              <div className="w-[80%]  border border-gray-200 rounded-[5px] p-2 flex items-center gap-[10px] max-md2:w-[100%]">
                <MdEmail className="text-[24px] text-gray-500" />
                <input
                  type="email"
                  placeholder="Email / ኢሜይል"
                  value={email}
                  onChange={(e) => setEmail(e?.target?.value)}
                  className="flex-1 p-2 text-[18px]  max-lg2:text-[14px] outline-none"
                />
              </div>
              <div className="w-[80%] border border-gray-200 rounded-[5px] p-2 flex items-center gap-[10px] max-md2:w-[100%]">
                <FaPhone className="text-[24px] text-gray-500" />
                <input
                  type="text"
                  value={primaryNumber}
                  placeholder="Primary Phone Number / ዋና ስልክ ቁጥር"
                  onChange={(e) => setPrimaryNumber(e?.target?.value)}
                  className="flex-1 p-2 text-[18px]  max-lg2:text-[14px] outline-none"
                />
              </div>
              <div className="w-[80%]  border border-gray-200 rounded-[5px] p-2 flex items-center gap-[10px] max-md2:w-[100%]">
                <BsFillTelephonePlusFill className="text-[24px] text-gray-500" />
                <input
                  type="text"
                  value={secondaryNumber}
                  placeholder="Secondary Phone Number (Optional) / ሁለተኛ ስልክ ቁጥር (አማራጭ)"
                  onChange={(e) => setSecondaryNumber(e?.target?.value)}
                  className="flex-1 p-2 text-[18px] max-lg2:text-[14px] outline-none"
                />
              </div>
              <div className="w-[80%] border border-gray-200 rounded-[5px] p-2 flex items-center gap-[10px] max-md2:w-[100%]">
                <BiSolidFoodMenu className="text-[30px] text-gray-500" />
                <select
                  onChange={(e) => setService(e?.target?.value)}
                  className="flex-1 p-2 text-[18px]  max-lg2:text-[14px] outline-none bg-transparent"
                >
                  {languageState?.lan === "en" && (
                    <option value={""}>Choose Service</option>
                  )}
                  {languageState?.lan === "amh" && (
                    <option value={""}>አገልግሎት ይምረጡ</option>
                  )}
                  {findActiveServices?.length > 0 &&
                    findActiveServices?.map((service, index) => {
                      return (
                        <option key={index} value={service?._id}>
                          {languageState?.lan === "en" && service?.name_en}
                          {languageState?.lan === "amh" && service?.name_am}
                        </option>
                      );
                    })}
                </select>
              </div>

              <div className="w-[80%]  border border-gray-200 rounded-[5px] p-2 flex items-center gap-[10px] max-md2:w-[100%]">
                <FaPeopleGroup className="text-[24px] text-gray-500" />
                <input
                  type="number"
                  min={1}
                  value={guests}
                  placeholder="Number of Guests / የእንግዶች ብዛት"
                  onChange={(e) => setGuests(e?.target?.value)}
                  className="flex-1 p-2 text-[18px] max-lg2:text-[14px] outline-none"
                />
              </div>
              <div className="w-[80%]  border border-gray-200 rounded-[5px] p-2 flex items-center gap-[10px] max-md2:w-[100%]">
                <BsFillCalendarDateFill className="text-[24px] text-gray-500" />
                <input
                  value={reservationDate}
                  type="date"
                  min={today}
                  onChange={(e) => setReservationDate(e?.target?.value)}
                  className="flex-1 p-2 text-[18px]  max-lg2:text-[14px] outline-none bg-transparent"
                />
              </div>
              <div className="w-[80%]  border border-gray-200 rounded-[5px] p-2 flex items-center gap-[10px] max-md2:w-[100%]">
                <FaRegClock className="text-[24px] text-gray-500" />
                <input
                  type="time"
                  value={reservationTime}
                  min={today}
                  onChange={(e) => setReservatonTime(e?.target?.value)}
                  className="flex-1 p-2 text-[18px]  max-lg2:text-[14px] outline-none bg-transparent"
                />
              </div>
            </div>

            <HomeBookDate service={service} />
          </div>
          {loading ? (
            <Loading
              addtionalStyle={
                "w-[100%] my-[20px] flex justify-center items-center"
              }
            />
          ) : (
            <div className="w-[50%] my-[20px] mx-auto max-sm1:w-[100%]">
              <button
                disabled={loading}
                onClick={createReservation}
                className="w-[100%] p-4 bg-[#4DB27D] text-white rounded-[5px] shadow-lg text-[20px] font-bold max-lg2:text-[14px]"
              >
                Book Now / አሁን ይያዙ
              </button>
            </div>
          )}
        </div>
      </div>

      {confirmationModal && (
        <ConfirmReservation setConfirmationModal={setConfirmationModal} />
      )}
    </div>
  );
}

export default BookADate;
