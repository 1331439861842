import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiRequest } from "../../util/request";

export const fetchGalleries = createAsyncThunk(
  "user/fetchGalleries",
  async ({ page, search, sort }) => {
    return apiRequest
      .get(
        `/gallery_api/get_gallery?page=${page}&search=${search}&sort=${sort}`,
        {
          headers: {
            "Content-Type": "application/json",
            get_gallery_api: process.env.REACT_APP_GET_GALLERY_API,
          },
        }
      )
      .then((res) => res.data);
  }
);

const initialState = {
  galleries: [],
  loading: false,
  error: false,
};

const gallerySlice = createSlice({
  name: "Gallery",
  initialState,

  extraReducers: (builder) => {
    builder.addCase(fetchGalleries.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchGalleries.fulfilled, (state, action) => {
      state.loading = false;
      state.galleries = action.payload;
      state.error = false;
    });
    builder.addCase(fetchGalleries.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export const galleryAction = gallerySlice.actions;

export default gallerySlice;
