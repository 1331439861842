import React from "react";

function ServerError() {
  return (
    <main className="grid min-h-[100vh] place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
      <div className="text-center">
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
          Oops!
        </h1>
        <p className="mt-6  leading-7 text-gray-600 text-[14px]">
          We're sorry, but there was an error processing your request. Please
          try again later.
        </p>
      </div>
    </main>
  );
}

export default ServerError;
