import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Service from "./pages/Services";
import Menu from "./pages/Menu";
import Events from "./pages/Events";
import Gallery from "./pages/Gallery";
import TopNavbar from "./components/TopNavBar";
import Navbar from "./components/Navbar";
import ResponsiveSidebar from "./components/ResponsiveSidebar";
import Footer from "./components/Footer";
import { useSelector } from "react-redux";
import Reservation from "./pages/Reservation";
import PageNotFound from "./pages/PageNotFound";
import EventDetail from "./pages/EventDetail";
import ServerError from "./pages/ServerError";

function App() {
  const navbarState = useSelector((state) => state?.navbar);

  return (
    <>
      <div className="fixed top-0 left-0 right-0 z-[200]">
        <TopNavbar />
        <Navbar />
      </div>

      {navbarState.isOpened && <ResponsiveSidebar />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Service />} />
        <Route path="/menu" element={<Menu />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/events" element={<Events />} />
        <Route path="/events/more_info/:id" element={<EventDetail />} />
        <Route path="/reservation" element={<Reservation />} />
        <Route path="/error/:name" element={<ServerError />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
