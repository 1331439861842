import React from "react";
import { navbarLinks } from "../util/data";
import { motion } from "framer-motion";
import { useLocation } from "react-router-dom";
import { FaFacebook, FaInstagram, FaTiktok, FaYoutube } from "react-icons/fa6";
import { useSelector } from "react-redux";

function ResponsiveSidebar() {
  const location = useLocation();
  const currentPath = location.pathname;
  const languageState = useSelector((state) => state.lan);
  return (
    <motion.div
      variants={{
        opened: { x: 0 },
        closed: { x: -300 },
      }}
      initial={"closed"}
      animate={"opened"}
      transition={{ duration: 0.5 }}
      className="w-[50%] min-h-[90vh] overflow-y-auto  hidden max-md2:block bg-black text-white z-[300] fixed top-[120px] left-0"
    >
      <div className="w-[80%] mx-auto mt-[50px]">
        <span className="font-bold">
          {languageState?.lan === "en" ? "Navigations" : "አሰሳዎች"}{" "}
        </span>
      </div>
      <div className="w-[70%] my-[30px] mx-auto flex flex-col gap-[20px]">
        {navbarLinks?.map((link) => {
          return (
            <a
              href={link.path}
              key={link.id}
              className={
                currentPath === link.path
                  ? "hover:underline hover:text-gray-200 flex items-center gap-[10px] text-[#4DB27D]"
                  : "hover:underline hover:text-gray-200 flex items-center gap-[10px]"
              }
            >
              {link?.icon}
              {languageState?.lan === "en" ? link?.name : link?.nameamh}
            </a>
          );
        })}
      </div>
      <div className="w-[90%] mt-[20px] mx-auto flex flex-col items-center gap-[20px]">
        <div className="w-[80%]">
          <span className="font-bold">
            {" "}
            {languageState?.lan === "en" ? "Social Media" : "ማህበራዊ ሚዲያ"}
          </span>
        </div>
        <div className="w-[60%] mx-auto flex flex-col gap-[20px]">
          <a
            href="https://www.facebook.com/TototTraditionalRestaurant/"
            className="flex items-center gap-[10px]"
          >
            <FaFacebook className="text-white" />
            {languageState?.lan === "en" ? (
              <span className="text-[14px]">Facebook</span>
            ) : (
              <span className="text-[14px]">ፌስቡክ</span>
            )}
          </a>
          <a
            href="https://www.tiktok.com/@totottraditional"
            className="flex items-center gap-[10px]"
          >
            <FaTiktok className="text-[12px] text-white" />

            {languageState?.lan === "en" ? (
              <span className="text-[14px]">Tiktok</span>
            ) : (
              <span className="text-[14px]">ቲክቶክ</span>
            )}
          </a>
          <a
            href="https://www.instagram.com/totottraditionalresturant/"
            className="flex items-center gap-[10px]"
          >
            <FaInstagram className="text-white" />
            {languageState?.lan === "en" ? (
              <span className="text-[14px]">Instagram</span>
            ) : (
              <span className="text-[14px]">ኢንስታግራም</span>
            )}
          </a>
          <a
            href="https://www.youtube.com/@totottraditionalrestaurant"
            className="flex items-center gap-[10px]"
          >
            <FaYoutube className="text-white" />
            {languageState?.lan === "en" ? (
              <span className="text-[14px]">Youtube</span>
            ) : (
              <span className="text-[14px]">ዩትዩብ</span>
            )}
          </a>
        </div>
      </div>
    </motion.div>
  );
}

export default ResponsiveSidebar;
