import React from "react";

function Map() {
  return (
    <div className="w-[100%] min-h-[700px] relative">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3940.6132086518455!2d38.805648!3d9.007692!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x164b8599dd6be6a5%3A0x6f01def6f8644ea2!2zVG90b3QgVHJhZGl0aW9uYWwgZm9vZCBIYWxsIHwgR2VyamkgfCDhibbhibbhibUgfCDhjIjhiK3hjII!5e0!3m2!1sen!2set!4v1709056799382!5m2!1sen!2set"
        className="w-[100%] h-[100%] absolute bottom-0 object-cover"
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        title="totot"
      ></iframe>
    </div>
  );
}

export default Map;
