import { MdEventNote, MdHome, MdMenuBook } from "react-icons/md";
import { TbPackages } from "react-icons/tb";
import { GrGallery } from "react-icons/gr";
import { IoMdInformationCircle } from "react-icons/io";

export const today = new Date().toISOString().split("T")[0];

export const navbarLinks = [
  {
    id: "1",
    name: "Home",
    nameamh: "መነሻ",
    icon: <MdHome />,
    path: "/",
  },
  {
    id: "2",
    name: "About",
    nameamh: "ስለ እኛ",
    icon: <IoMdInformationCircle />,
    path: "/about",
  },
  {
    id: "3",
    name: "Services",
    nameamh: "አገልግሎቶች",
    icon: <TbPackages />,
    path: "/services",
  },
  {
    id: "4",
    name: "Menu",
    nameamh: "ሜኑ",
    icon: <MdMenuBook />,
    path: "/menu",
  },
  {
    id: "5",
    name: "Gallery",
    icon: <GrGallery />,
    nameamh: "ምስሎች",
    path: "/gallery",
  },
  {
    id: "6",
    name: "Events",
    icon: <MdEventNote />,
    nameamh: "አዳዲስ ክስተቶች",
    path: "/events",
  },
];

export const homeServices = [
  {
    id: "1",
    name: "Weddings",
    img: "../Images/w-1.jpg",
  },
  {
    id: "2",
    name: "Lunch/Dinner",
    img: "../Images/lunch-dinner.jpg",
  },
  {
    id: "3",
    name: "Get Together",
    img: "../Images/get-together.jpg",
  },
];

export const popularMenus = [
  {
    id: "1",
    name: "Combo",
    img: "w-1.jpg",
  },
  {
    id: "2",
    name: "Fasting Food",
    img: "lunch-dinner.jpg",
  },
  {
    id: "3",
    name: "Kitfo",
    img: "get-together.jpg",
  },
];

export const latestEvents = [
  {
    id: "1",
    title: "title-1",
    description:
      "Prepare to be mesmerized by our live band and traditional dancers.",
    img: ["ownerted.png"],
  },
  {
    id: "2",
    title: "title-2",
    description:
      "Prepare to be mesmerized by our live band and traditional dancers.",
    img: ["ownerted.png"],
  },
];

export const services = [
  {
    id: "1",
    title: "wedding, Engagement, Kilikil, Mels",
    caption: "1st Level Special Totot's Buffet",
    serviceImage: "w-1.jpg",
    serviceCategory: [
      {
        id: "1.1",
        category: "1-100",
        varieties: [
          {
            id: "1.1.1",
            name: "Kurt",
            quantity: 0,
          },
          {
            id: "1.1.2",
            name: "Injera",
            quantity: 2,
          },
          {
            id: "1.1.3",
            name: "Bread",
            quantity: 2,
          },
          {
            id: "1.1.4",
            name: "Kocho",
            quantity: 2,
          },
          {
            id: "1.1.5",
            name: "Wot",
            quantity: 5,
          },
          {
            id: "1.1.6",
            name: "Foreign foods",
            quantity: 7,
          },
          {
            id: "1.1.7",
            name: "Kitfo",
            quantity: 9,
          },
        ],
        additionalServices: [
          "Hall",
          "decoration",
          "standard hospitality by trained professionals",
          "50% DJ discount",
          "Welcome message by Totot Bahilband",
        ],
      },
    ],
  },
];

export const menus = [
  {
    id: "1",
    title: "Rice with Veg",
    image: "menu-1.jpg",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum quo dolores dolore natus quae consectetur quidem neque nostrum adipisci repellendus?",
  },
  {
    id: "2",
    title: "Menu-2",
    image: "menu-2.jpg",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum quo dolores dolore natus quae consectetur quidem neque nostrum adipisci repellendus?",
  },
  {
    id: "3",
    title: "Menu-3",
    image: "menu-3.jpg",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum quo dolores dolore natus quae consectetur quidem neque nostrum adipisci repellendus?",
  },
  {
    id: "4",
    title: "Menu-4",
    image: "menu-1.jpg",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum quo dolores dolore natus quae consectetur quidem neque nostrum adipisci repellendus?",
  },
  {
    id: "5",
    title: "Menu-5",
    image: "menu-3.jpg",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum quo dolores dolore natus quae consectetur quidem neque nostrum adipisci repellendus?",
  },
  {
    id: "6",
    title: "Menu-6",
    image: "menu-1.jpg",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum quo dolores dolore natus quae consectetur quidem neque nostrum adipisci repellendus?",
  },
];

export const galleryImages = [
  {
    id: "1",
    image: "menu-1.jpg",
  },
  {
    id: "2",
    image: "menu-2.jpg",
  },
  {
    id: "3",
    image: "menu-3.jpg",
  },
  {
    id: "4",
    image: "menu-1.jpg",
  },
  {
    id: "5",
    image: "menu-2.jpg",
  },
  {
    id: "6",
    image: "menu-3.jpg",
  },
];

export const events = [
  {
    id: "1",
    title: "November 18, 2024 Our grand anniversary will be held",
    description1:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum quo dolores dolore natus adipisci repellendus...",
    description2:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum quo dolores dolore natus adipisci repellendus...",
    image: "why-3.png",
  },
  {
    id: "2",
    title: "November 18, 2024 Our grand anniversary will be held",
    description1:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum quo dolores dolore natus adipisci repellendus...",
    description2:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum quo dolores dolore natus adipisci repellendus...",
    image: "why-3.png",
  },
  {
    id: "3",
    title: "November 18, 2024 Our grand anniversary will be held",
    description1:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum quo dolores dolore natus adipisci repellendus...",
    description2:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum quo dolores dolore natus adipisci repellendus...",
    image: "why-3.png",
  },
];
