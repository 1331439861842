import Banner from "../components/Banner";
import BookADate from "../components/BookADate";
import ChooseUs from "../components/ChooseUs";
import LatestEvents from "../components/LatestEvents";
import Map from "../components/Map";
import OurService from "../components/OurService";
import PopularMenu from "../components/PopularMenu";
import { useDispatch, useSelector } from "react-redux";
import { fetchServices } from "../REDUX/slices/servicesSlice";
import { useEffect } from "react";
import { fetchMenus } from "../REDUX/slices/menuSlice";
import { fetchEvents } from "../REDUX/slices/eventSlice";

export default function Home() {
  const dispatch = useDispatch();
  const serviceState = useSelector((state) => state?.services);
  const menuState = useSelector((state) => state?.menus);
  const eventState = useSelector((state) => state?.events);
  useEffect(() => {
    dispatch(fetchServices());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(fetchMenus());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(fetchEvents({ sort: -1 }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <div className="overflow-x-hidden">
      <Banner />
      {serviceState?.fullfiled && serviceState?.services?.length !== 0 && (
        <OurService />
      )}
      <BookADate />
      {menuState?.fullfilled && menuState?.menus?.length !== 0 && (
        <PopularMenu />
      )}
      {eventState?.fullfilled && eventState?.events?.length !== 0 && (
        <LatestEvents />
      )}
      <ChooseUs />
      <Map />
    </div>
  );
}
