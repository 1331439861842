import ServiceList from "../components/ServiceList";
import { useSelector } from "react-redux";

function Services() {
  const languageState = useSelector((state) => state.lan);

  return (
    <div className="w-[100%]  my-[120px] p-2">
      <div className="w-[100%] my-[10px]  py-2 flex justify-center items-center  border-b border-gray-200">
        {languageState?.lan === "en" && (
          <span className="service-header font-bold text-[50px] max-lg2:text-[40px] text-[#4DB27D]">
            Services
          </span>
        )}
        {languageState?.lan === "amh" && (
          <span className="service-header font-bold text-[50px] max-lg2:text-[40px] text-[#4DB27D]">
            አገልግሎቶች
          </span>
        )}
      </div>

      <ServiceList />
    </div>
  );
}

export default Services;
