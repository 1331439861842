import React, { useEffect } from "react";
import { fetchMenus } from "../REDUX/slices/menuSlice";
import Loading from "../components/Loading";
import { useDispatch, useSelector } from "react-redux";
import Error from "../components/Error";

function MenuList() {
  const dispatch = useDispatch();
  const menuState = useSelector((state) => state.menus);
  const languageState = useSelector((state) => state.lan);

  useEffect(() => {
    dispatch(fetchMenus());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterMenus = menuState?.menus?.filter(
    (menu) => menu?.status === "active"
  );

  if (!filterMenus || filterMenus?.length === 0) {
    return (
      <div className="min-h-[500px] my-[100px] flex flex-col justify-center items-center gap-[10px]">
        {languageState?.lan === "en" && (
          <span className="text-[30px] text-[#4DB27D] font-bold service-header">
            Nothing Here!
          </span>
        )}
        {languageState?.lan === "amh" && (
          <span className="text-[30px] text-[#4DB27D] font-bold service-header">
            ምንም የተለቀቁ ሜኑ የለም
          </span>
        )}
      </div>
    );
  }
  if (menuState?.error) return <Error name={"menu"} height="300px" />;
  return (
    <div className="w-[80%] min-h-[500px] mx-auto my-[30px] grid grid-cols-2  gap-[20px] flex-wrap p-2  max-lg2:w-[90%] max-lg2:gap-[0px] max-lg:gap-[10px] max-md1:flex-col">
      {menuState?.loading && filterMenus?.length === 0 ? (
        <Loading addtionalStyle="w-[100%] min-h-[500px] col-span-2 flex justify-center items-center" />
      ) : (
        filterMenus?.map((menu, index) => {
          return (
            <div
              key={index}
              className="w-[100%] col-span-1 my-[20px] flex justify-start items-start gap-[20px]   max-lg:flex-col max-md1:col-span-2 max-md1:mx-auto"
            >
              <div>
                <img
                  src={`${process.env.REACT_APP_BACKEND_IMAGE_URL}/MenuImages/${menu?.menu_image}`}
                  className="w-[300px] h-[200px] aspect-square object-cover rounded-[10px]  max-lg:h-[300px] max-md1:w-[600px] max-md1:h-[300px]"
                  alt=""
                />
              </div>
              <div className="w-[100%] flex flex-col gap-[10px]">
                {languageState?.lan === "en" && (
                  <>
                    <span className="capitalize text-[30px] service-header text-[#4DB27D]">
                      {menu?.name_en}
                    </span>
                    <span className="text-[18px] w-[90%] leading-[30px] max-md2:w-[100%] max-lg2:text-[18px]  text-[#6A6A6A]">
                      {menu?.description_en}
                    </span>
                  </>
                )}

                {languageState?.lan === "amh" && (
                  <>
                    <span className="text-[30px] text-[#4DB27D]">
                      {menu?.name_am}
                    </span>
                    <span className="text-[16px] w-[90%] max-md2:w-[100%] leading-[30px] text-[#6A6A6A] max-lg2:text-[18px]">
                      {menu?.description_am}
                    </span>
                  </>
                )}
              </div>
            </div>
          );
        })
      )}
    </div>
  );
}

export default MenuList;
