import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { fetchEvents } from "../REDUX/slices/eventSlice";
function EventDetail() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const eventState = useSelector((state) => state.events);
  const languageState = useSelector((state) => state.lan);

  useEffect(() => {
    dispatch(fetchEvents({ page: 1, sort: -1, title: "" }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const activeEvents = eventState?.events?.events?.filter(
    (event) => event?.status === "active"
  );

  const findEvent = activeEvents?.find((event) => event?._id === id);

  if (!findEvent || findEvent?.status === "inactive") {
    return (
      <div className="min-h-[500px] my-[100px] flex flex-col justify-center items-center gap-[10px]">
        <span className="text-[30px] font-bold service-header">
          This event has been removed.{" "}
        </span>
        <button
          onClick={() => navigate("/events")}
          className="py-2 px-4 bg-[#4DB27D] text-[14px] text-white rounded-[5px]"
        >
          Events
        </button>
      </div>
    );
  }

  return (
    <div className="w-[100%] my-[100px]">
      <div className="w-[100%] min-h-[700px]  relative">
        <div className="absolute top-0 left-0 w-[100%] h-[100%] bg-black opacity-40" />
        <div className="w-[80%] mx-auto flex flex-col justify-end gap-[10px] max-md2:items-center">
          <div
          
            className="w-[70%] text-[50px] z-[100] absolute top-[50%] uppercase flex gap-[10px] items-center font-extrabold max-lg2:text-[40px] max-lg1:text-[30px] max-lg2:w-[70%] max-md2:text-[24px] max-md2:text-center max-md2:w-[90%] max-md2:top-[40%]"
          >
            <span className="text-white">
              {languageState.lan === "en"
                ? findEvent?.title_en
                : findEvent?.title_am}
            </span>
          </div>
        </div>

        <div className="h-[100%] ">
          <img
            src={`${process.env.REACT_APP_BACKEND_IMAGE_URL}/EventImages/${findEvent?.thumbnail}`}
            alt={""}
            className="w-[100%] h-[700px] object-cover"
          />
        </div>
      </div>
      <div className="w-[90%] my-[70px] mx-auto flex flex-col gap-[20px]">


        <div className="grid grid-cols-2 gap-[20px]">
        {findEvent?.image_one && findEvent?.image_two !=="" && <div className="w-[100%] col-span-1 max-md1:col-span-2">
            <img
              src={`${process.env.REACT_APP_BACKEND_IMAGE_URL}/EventImages/${findEvent?.image_one}`}
              alt={""}
              className="w-[100%] h-[500px] object-cover rounded-[10px] max-lg2:h-[400px]"
            />
          </div>}
          
          <div className="w-[100%]  col-span-1  mx-auto  gap-[10px]  max-md1:col-span-2 max-md2:items-center">
            <div
              variants={{
                hidden: { opacity: 0, y: 75 },
                show: { opacity: 1, y: 0 },
              }}
              initial="hidden"
              animate="show"
              transition={{ duration: 0.5, delay: 1 }}
              className="w-[100%] text-[18px] rounded-[10px] leading-[30px] flex gap-[10px] items-center max-md2:text-center max-md2:w-[100%]"
            >
              <span className="text-gray-500">
                {languageState.lan === "en"
                  ? findEvent?.description_en
                  : findEvent?.description_am}
              </span>
            </div>
          </div>
        </div>
        <div className="w-[100%] mx-auto my-[20px]">
          <div className="w-[100%] grid grid-cols-2 gap-[20px]">
            {findEvent?.image_two && findEvent?.image_two !== "" && (
              <div className="w-[100%] col-span-1 max-md1:col-span-2">
                <img
                  src={`${process.env.REACT_APP_BACKEND_IMAGE_URL}/EventImages/${findEvent?.image_two}`}
                  alt={""}
                  className="w-[100%] h-[500px] object-cover rounded-[10px]"
                />
              </div>
            )}
            {findEvent?.image_three && findEvent?.image_three !== "" && (
              <div className="w-[100%] col-span-1 max-md1:col-span-2">
                <img
                  src={`${process.env.REACT_APP_BACKEND_IMAGE_URL}/EventImages/${findEvent?.image_three}`}
                  alt={""}
                  className="w-[100%] h-[500px] rounded-[10px] object-cover"
                />
              </div>
            )}
          </div>
        </div>

        {findEvent?.video_url && (
          <div>
            <iframe
              height="400"
              className="col-span-1 w-[50%] max-md2:w-[100%] rounded-[10px]"
              src={findEvent?.video_url}
              allowFullScreen
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              sandbox="allow-scripts allow-same-origin allow-popups"
            ></iframe>
          </div>
        )}
      </div>
    </div>
  );
}

export default EventDetail;
