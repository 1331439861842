import { configureStore } from "@reduxjs/toolkit";
import responsiveNavbarSlice from "./slices/ResponsiveNavbarSlice";
import translationSlice from "./slices/pageTranslationSlice";
import serviceSlice from "./slices/servicesSlice";
import menuSlice from "./slices/menuSlice";
import eventSlice from "./slices/eventSlice";
import confirmReservationSlice from "./slices/confirmReservationSlice";
import gallerySlice from "./slices/gallerySlice";
import categorySlice from "./slices/categorySlice";

const store = configureStore({
  reducer: {
    navbar: responsiveNavbarSlice.reducer,
    lan: translationSlice.reducer,
    services: serviceSlice.reducer,
    menus: menuSlice.reducer,
    events: eventSlice.reducer,
    confirmationReservation: confirmReservationSlice.reducer,
    gallery: gallerySlice.reducer,
    categories: categorySlice.reducer,
  },
});

export default store;
