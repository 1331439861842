import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchServices } from "../REDUX/slices/servicesSlice";
import Loading from "../components/Loading";
import Error from "../components/Error";
function ServiceList() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const serviceState = useSelector((state) => state?.services);
  const languageState = useSelector((state) => state?.lan);

  useEffect(() => {
    dispatch(fetchServices());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterServices = serviceState?.services?.filter(
    (service) => service?.status === "active"
  );

  if (serviceState?.error) return <Error name="service" height="300px" />;
  return (
    <div className="my-[20px] py-2 bg-white">
      {serviceState?.loading ? (
        <Loading addtionalStyle="w-[100%] min-h-[500px] col-span-2 flex justify-center items-center" />
      ) : filterServices?.length === 0 ? (
        <div className="w-[100%] min-h-[500px] col-span-2 flex justify-center items-center">
          <span className="font-bold service-header text-[30px] text-[#4DB27D]">
            No result found
          </span>
        </div>
      ) : (
        filterServices?.map((service, index) => {
          return (
            <div
              key={index}
              className="w-[70%] bg-white border border-gray-300 mx-auto my-[30px]  max-lg1:w-[90%] rounded-[10px] "
            >
              <div className="w-[100%] h-[100%] rounded-[10px] ">
                <div className="w-[95%] min-h-[200px]  mx-auto my-[20px] py-4 flex gap-[30px] max-lg1:flex-col max-lg1:w-[80%]">
                  <div className="w-[30%]  max-lg1:w-[100%] max-lg1:mx-auto">
                    <img
                      src={`${process.env.REACT_APP_BACKEND_IMAGE_URL}/PackageImages/${service?.package_img}`}
                      className="rounded-[10px] w-[100%] h-[300px] max-lg1:h-[250px] object-cover max-sm1:h-[250px]"
                      alt=""
                    />
                  </div>

                  <div className="flex-1">
                    <div className="w-[100%] my-[20px] flex justify-between items-center max-sm1:flex-col max-sm1:gap-5 max-sm1:items-start">
                      <div className="flex items-center gap-[10px]">
                        <span className="text-[30px] capitalize service-header text-[#4DB27D]">
                          {languageState?.lan === "en" && service?.name_en}
                          {languageState?.lan === "amh" && service?.name_am}
                        </span>
                      </div>
                      <div className="max-md1:hidden">
                        <button
                          onClick={() =>
                            navigate("/reservation", {
                              state: {
                                id: service?._id,
                              },
                            })
                          }
                          className="py-2 px-4 bg-[#4DB27D] text-white font-bold text-[16px] rounded-[5px] shadow-lg"
                        >
                          {languageState?.lan === "amh" && "ቦታ ያስይዙ"}
                          {languageState?.lan === "en" && "Book Service"}
                        </button>
                      </div>
                    </div>

                    <div>
                      <div className="my-[10px] flex flex-col gap-[10px]">
                        {languageState?.lan === "amh" && (
                          <span className="font-bold text-[24px] max-lg2:text-[20px]">
                            ሜኑ
                          </span>
                        )}
                        {languageState?.lan === "en" && (
                          <span className="font-bold text-[24px] max-lg2:text-[20px]">
                            Menu
                          </span>
                        )}
                        {languageState?.lan === "en" && (
                          <span className="text-[18px] max-lg2:text-[18px] leading-[30px] text-gray-500">
                            {service?.menu_en}
                          </span>
                        )}
                        {languageState?.lan === "amh" && (
                          <span className="text-[18px] text-gray-500">
                            {service?.menu_am}
                          </span>
                        )}

                        {(service?.additional_package_en ||
                          service?.additional_package_am) && (
                          <div className="w-[100%] my-[10px] flex flex-col gap-[10px]">
                            {languageState?.lan === "en" && (
                              <span className="font-bold text-[24px] max-lg2:text-[20px]">
                                Additional Services
                              </span>
                            )}
                            {languageState?.lan === "amh" && (
                              <span className="font-bold text-[24px] max-lg2:text-[20px]">
                                ተጨማሪ አገልግሎቶች
                              </span>
                            )}

                            <span className="text-[18px] max-lg2:text-[18px] leading-[30px] text-gray-500">
                              {languageState?.lan === "en" &&
                                service?.additional_package_en}
                              {languageState?.lan === "amh" &&
                                service?.additional_package_am}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="my-[20px] hidden max-md1:block">
                      <button
                        onClick={() =>
                          navigate("/reservation", {
                            state: {
                              id: service?._id,
                            },
                          })
                        }
                        className="w-[100%] py-2 px-4 bg-[#4DB27D] text-white font-bold text-[18px] rounded-[5px] shadow-lg"
                      >
                        {languageState?.lan === "amh" && "ቦታ ያስይዙ"}
                        {languageState?.lan === "en" && "Book Service"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })
      )}
    </div>
  );
}

export default ServiceList;
