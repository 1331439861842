import { FaArrowRight } from "react-icons/fa6";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchMenus } from "../REDUX/slices/menuSlice";
import Loading from "./Loading";

export default function PopularMenu() {
  const dispatch = useDispatch();
  const menuState = useSelector((state) => state.menus);
  const languageState = useSelector((state) => state.lan);



  useEffect(() => {
    dispatch(fetchMenus());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const popularMenus = menuState?.menus?.filter(
    (menu) => menu?.popular_menu === "yes"
  );

  const filterMenus = popularMenus?.filter((menu) => menu?.status === "active");
  return (
    <div  className="w-[100%]  p-2 min-h-[600px] bg-gray-100">
      <div className="w-[80%] my-[30px] mt-[50px] mx-auto flex flex-col gap-[20px] max-lg1:w-[90%]">
        <div
        
          className="flex justify-between items-center"
        >
          <div className="flex items-center gap-[10px] text-[50px] max-lg2:text-[30px] service-header">
            {languageState?.lan === "en" && <span>Popular</span>}
            {languageState?.lan === "en" && (
              <span className="text-[#4DB27D]">Menus</span>
            )}
            {languageState?.lan === "amh" && (
              <span className="max-sm1:text-[24px]">ታዋቂ</span>
            )}
            {languageState?.lan === "amh" && (
              <span className="text-[#4DB27D] max-sm1:text-[24px]">ምግቦቻችን</span>
            )}
          </div>

          <a
            href="/menu"
            className="flex items-center gap-[10px] hover:text-[#4DB27D]"
          >
            {languageState?.lan === "en" && (
              <span className="font-bold service-header text-[20px] max-lg2:text-[18px] max-sm1:text-[18px]">
                See more
              </span>
            )}
            {languageState?.lan === "amh" && (
              <span className="font-bold service-header text-[20px] max-lg2:text-[14px]">
                ሁሉንም ይመልከቱ
              </span>
            )}

            <FaArrowRight />
          </a>
        </div>

        <div className="w-[100%] mt-[50px] mx-auto flex justify-start items-center gap-[20px]  max-md2:flex-wrap max-sm1:flex-col">
          {menuState?.loading || filterMenus?.length === 0 ? (
            <Loading
              addtionalStyle={
                "w-[100%] my-[20px] flex justify-center items-center"
              }
            />
          ) : (
            filterMenus?.slice(0, 3).map((pm, index) => {
              return (
                <div
                  
                  className="w-[30%] flex flex-col bg-white shadow-lg p-1 rounded-[10px] max-[750px]:w-[48%] max-sm1:w-[90%]"
                  key={index}
                >
                  <div className="w-[90%] mt-[20px] mx-auto">
                    <img
                      src={`${process.env.REACT_APP_BACKEND_IMAGE_URL}/MenuImages/${pm?.menu_image}`}
                      className="w-[100%] h-[250px] rounded-[5px]  object-cover max-md2:h-[150px]"
                      alt={""}
                    />
                  </div>
                  <div className="w-[100%] flex justify-center items-center p-4">
                    {languageState?.lan === "en" && (
                      <span className="service-header roboto font-semibold text-center text-[20px] text-[#4DB27D] max-lg2:text-[18px]">
                        {pm?.name_en}
                      </span>
                    )}

                    {languageState?.lan === "amh" && (
                      <span className="service-header font-semibold text-center text-[20px] text-[#4DB27D] max-md2:text-[18px]">
                        {pm?.name_am}
                      </span>
                    )}
                  </div>
                </div>
              );
            })
          )}
        </div>
      </div>
    </div>
  );
}
