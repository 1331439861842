import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isOpened: false,
};

const responsiveNavbarSlice = createSlice({
  name: "RNAVBAR",
  initialState,
  reducers: {
    toggleNavbar: (state, action) => {
      state.isOpened = !state.isOpened;
    },
  },
});

export const navbarActions = responsiveNavbarSlice.actions;

export default responsiveNavbarSlice;
