import React from "react";
import { FaCheckCircle } from "react-icons/fa";

function ConfirmReservation({ setConfirmationModal }) {
  return (
    <div
      className="flex justify-center"
      onClick={() => setConfirmationModal(false)}
    >
      <div className="w-[100%] h-[100vh] fixed top-0 left-0 bg-black z-[100] opacity-60"></div>
      <div className="w-[30%] h-[400px] mx-auto flex flex-col justify-center items-center gap-[20px]  bg-white shadow-lg rounded-[20px] fixed top-[25%]  z-[100] max-lg1:w-[50%] max-md1:w-[80%] max-sm1:h-[300px] max-sm1:top-[30%]">
        <div>
          <FaCheckCircle className="text-[70px] text-[#4DB27D]" />
        </div>
        <div className="flex flex-col items-center">
          <span className="text-[30px] font-bold">Success</span>
          <span className="text-[14px] w-[70%] text-center text-gray-500">
            {" "}
            We will contact you as soon as possible to confirm your reservation.
          </span>
        </div>
      </div>
    </div>
  );
}

export default ConfirmReservation;
