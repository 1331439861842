import logo from "../Images/logo2.png";
import { navbarLinks } from "../util/data";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

function Navbar({ bgType }) {
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  const languageState = useSelector((state) => state?.lan);
  return (
    <div
      className={
        bgType === "transparent"
          ? "w-[100%] h-[90px]  z-[1000] bg-transparent max-lg2:h-[70px]"
          : "w-[100%] h-[90px]  bg-[#0E0E0E] z-[1000] max-lg2:h-[70px]"
      }
    >
      <div className="w-[80%] h-[100%] mx-auto  flex justify-between max-lg:w-[90%]">
        <div className=" pt-2">
          <img src={logo} className="h-[100%]  object-cover" alt="" />
        </div>
        <div className="flex items-center gap-[30px] text-white max-md2:hidden">
          {navbarLinks?.map((link) => {
            return (
              <a
                href={link.path}
                key={link.id}
                className={
                  currentPath === link.path
                    ? "hover:underline hover:text-gray-200 flex items-center gap-[10px] text-[#4DB27D]"
                    : "hover:underline hover:text-gray-200 flex items-center gap-[10px]"
                }
              >
                <span className="text-[20px] max-lg2:text-[16px]">
                  {languageState?.lan === "en" ? link?.name : link?.nameamh}
                </span>
              </a>
            );
          })}
        </div>
        <div>
          <button
            onClick={() => navigate("/reservation")}
            className="py-2 px-4 h-[100%] bg-[#4DB27D] font-bold text-[16px] text-white max-md1:text-[14px] max-md1:font-bold"
          >
            {languageState?.lan === "amh" && "ቦታ ያስይዙ"}
            {languageState?.lan === "en" && "Book Service"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
