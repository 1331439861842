import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchGalleries } from "../REDUX/slices/gallerySlice";
import Loading from "../components/Loading";
import Error from "./Error";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

function GalleryList({ category }) {
  const dispatch = useDispatch();
  const galleryState = useSelector((state) => state?.gallery);
  const languageState = useSelector((state) => state?.lan);
  const [pageNum, setPageNum] = useState(1);
  const [sortingNum, setSortingNum] = useState(-1);

  useEffect(() => {
    dispatch(
      fetchGalleries({
        page: pageNum,
        search: category,
        sort: sortingNum,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNum, category, sortingNum]);

  const handlePrevious = () => {
    if (pageNum <= 1) {
      setPageNum(1);
    } else {
      setPageNum(pageNum - 1);
    }
  };

  const handleNext = () => {
    if (pageNum >= galleryState?.galleries?.totalPages) {
      setPageNum(galleryState?.galleries?.totalPages);
    } else {
      setPageNum(pageNum + 1);
    }
  };

  if (galleryState?.error) return <Error name="gallery" height="300px" />;

  return (
    <>
      {galleryState?.loading ? (
        <></>
      ) : (
        galleryState?.galleries?.galleries?.length > 0 &&
        galleryState?.galleries?.totalPages && (
          <div className="w-[90%] flex justify-end items-center mb-[20px] gap-5">
            <button
              onClick={handlePrevious}
              className="mx-1 w-[30px] h-[30px] flex justify-center items-center cursor-pointer text-white border border-[#039674] bg-[#039674] rounded-[50%] hover:bg-transparent hover:text-[#039674] transition duration-500 max-[400px]:px-2 outline-none"
            >
              <IoIosArrowBack />
            </button>
            <span className="text-gray-500 font-semibold">
              {galleryState?.galleries?.currentPage} of{" "}
              {galleryState?.galleries?.totalPages}
            </span>

            <button
              onClick={handleNext}
              className={
                "mx-1 w-[30px] h-[30px] flex justify-center items-center cursor-pointer text-white border border-[#039674] bg-[#039674] rounded-[50%] hover:bg-transparent hover:text-[#039674] transition duration-500 max-[400px]:px-2 outline-none"
              }
            >
              <IoIosArrowForward />
            </button>

            <div className="flex items-center gap-2">
              <select
                value={sortingNum}
                onChange={(e) => setSortingNum(e?.target?.value)}
                className="py-2 px-4 border border-gray-300 rounded-[5px] outline-none text-[12px] font-medium"
              >
                {languageState?.lan === "en" && (
                  <option value={-1}>Latest</option>
                )}
                {languageState?.lan === "en" && (
                  <option value={1}>Oldest</option>
                )}
                {languageState?.lan === "amh" && (
                  <option value={-1}>የቅርብ ጊዜ</option>
                )}
                {languageState?.lan === "amh" && <option value={1}>የቆዩ</option>}
              </select>
            </div>
          </div>
        )
      )}
      <div className="w-[80%] mx-auto  grid grid-cols-3 gap-4 max-md2:w-[90%] max-md2:grid-cols-2 max-sm1:grid-cols-1">
        {galleryState?.loading ? (
          <Loading
            addtionalStyle={
              "w-[100%] min-h-[500px] col-span-3 my-[20px] flex justify-center items-center"
            }
          />
        ) : galleryState?.galleries?.galleries?.length !== 0 ? (
          galleryState?.galleries?.galleries?.map((gallery, index) => {
            return (
              <div
                className="col-span-1  relative group overflow-hidden"
                key={index}
              >
                <img
                  src={`${process.env.REACT_APP_BACKEND_IMAGE_URL}/GalleryImages/${gallery?.image}`}
                  className="object-cover w-[100%] h-[300px] rounded-[10px]"
                  alt=""
                />
              </div>
            );
          })
        ) : (
          galleryState?.galleries?.galleries?.length === 0 && (
            <div className="w-[100%] col-span-3 min-h-[300px] flex justify-center items-center">
              <span className="font-bold service-header text-[30px] text-[#4DB27D]">
                No result found
              </span>
            </div>
          )
        )}
      </div>
    </>
  );
}

export default GalleryList;
