import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  fullName: "",
  subject: "",
  category: "",
  email: "",
  primaryNumber: "",
  secondaryNumber: "",
  service: "",
  guests: "",
  reservationDate: "",
  reservationTime: "",
};

const confirmReservationSlice = createSlice({
  name: "CONFIRMRESERVATION",
  initialState,
  reducers: {
    setUserInformation: (state, action) => {
      state.fullName = action.payload.fullName;
      state.subject = action.payload.subject;
      state.category = action.payload.category;
      state.email = action.payload.email;
      state.primaryNumber = action.payload.primaryNumber;
      state.secondaryNumber = action.payload.secondaryNumber;
      state.service = action.payload.service;
      state.guests = action.payload.guests;
      state.reservationDate = action.payload.reservationDate;
      state.reservationTime = action.payload.reservationTime;
    },
  },
});

export const confirmReservationAction = confirmReservationSlice.actions;

export default confirmReservationSlice;
