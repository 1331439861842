import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FaArrowRight } from "react-icons/fa6";
import { fetchServices } from "../REDUX/slices/servicesSlice";
import Loading from "./Loading";

export default function OurService() {
  
  const dispatch = useDispatch();
  const serviceState = useSelector((state) => state.services);
  const languageState = useSelector((state) => state.lan);

  useEffect(() => {
    dispatch(fetchServices());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterServices = serviceState?.services?.filter(
    (service) => service?.status === "active"
  );


  return (
    <div

      className="p-2 min-h-[80vh] my-[20px] max-lg2:min-h-[60vh]"
    >
      <div className="w-[80%] mt-[50px] mx-auto flex flex-col gap-[30px] max-lg2:w-[90%]">
        <div
        
          className="flex justify-between items-center"
        >
          {languageState?.lan === "en" && (
            <div className="flex items-center gap-[10px] text-[50px] service-header max-lg2:text-[30px]">
              <span>Our</span>
              <span className="text-[#4DB27D]">Services</span>
            </div>
          )}
          {languageState?.lan === "amh" && (
            <div className="flex items-center gap-[10px] text-[30px] service-header max-sm1:text-[24px]">
              <span>የእኛ</span>

              <span className="text-[#4DB27D]">አገልግሎቶች</span>
            </div>
          )}

          <a
            href="/services"
            className="flex items-center gap-[10px] hover:text-[#4DB27D] text-[20px] max-lg2:text-[16px] max-sm1:text-[16px]"
          >
            {languageState?.lan === "en" && (
              <span className="font-bold service-header max-lg2:text-[18px] max-sm1:text-[18px]">
                See more
              </span>
            )}
            {languageState?.lan === "amh" && (
              <span className="font-bold service-header">ሁሉንም ይመልከቱ</span>
            )}

            <FaArrowRight />
          </a>
        </div>

        <div className="w-[90%] mt-[50px] mx-auto flex justify-between items-center gap-[20px] overflow-hidden  relative max-lg2:w-[100%] max-lg2:flex-wrap max-sm:flex-col">
          {serviceState?.loading || filterServices?.length === 0 ? (
            <Loading
              addtionalStyle={
                "w-[100%] my-[20px] flex justify-center items-center"
              }
            />
          ) : (
            filterServices?.slice(0, 3)?.map((service, index) => {
              return (
                <div
                 
                  className="w-[30%] mx-auto  rounded-[5px]  cursor-pointer overflow-hidden max-lg:w-[45%] max-md2:w-[48%] max-sm1:w-[90%]"
                  key={index}
                >
                  <div className="w-[100%] overflow-hidden">
                    <img
                      src={`${process.env.REACT_APP_BACKEND_IMAGE_URL}/PackageImages/${service?.package_img}`}
                      className="w-[100%] h-[400px] z-[1] rounded-tl-[5px] rounded-tr-[5px] hover:scale-110 object-cover transition-transform duration-1000 ease-in-out max-md2:h-[200px]"
                      alt={service?.name}
                    />
                  </div>
                  <div className="w-[100%]   p-4 text-center text-white bg-black ">
                    {languageState?.lan === "en" && (
                      <span className="roboto font-bold capitalize text-[18px] max-lg2:text-[16px]">
                        {" "}
                        {service?.name_en}
                      </span>
                    )}

                    {languageState?.lan === "amh" && (
                      <span> {service?.name_am}</span>
                    )}
                  </div>
                </div>
              );
            })
          )}
        </div>
      </div>
    </div>
  );
}
