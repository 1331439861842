import React from "react";
import MenuList from "../components/MenuList";
import { useSelector } from "react-redux";

function Menu() {
  const languageState = useSelector((state) => state?.lan);

  return (
    <div className="w-[100%] my-[110px] p-2 bg-white">
      <div className="w-[100%] my-[20px] py-2 flex justify-center items-center  border-b border-gray-200">
        {languageState?.lan === "amh" && (
          <span className="service-header text-[50px] font-bold max-lg2:text-[40px] text-[#4DB27D]">ሜኑ</span>
        )}
        {languageState?.lan === "en" && (
          <span className="service-header text-[50px] font-bold max-lg2:text-[40px] text-[#4DB27D]">
            Menus
          </span>
        )}
      </div>

      <MenuList />
    </div>
  );
}

export default Menu;
