import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { categoryAction, fetchCatagories } from "../REDUX/slices/categorySlice";
import GalleryList from "../components/GalleryList";

function Gallery() {
  const dispatch = useDispatch();
  const categoryState = useSelector((state) => state?.categories);
  const languageState = useSelector((state) => state?.lan);

  useEffect(() => {
    dispatch(fetchCatagories());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterCategory = categoryState?.categories?.filter(
    (category) => category?.status === "active"
  );

  return (
    <div className="w-[100%] my-[130px]">
      <div className="w-[100%] my-[20px] py-2 flex justify-center items-center  border-b border-gray-200">
        {languageState?.lan === "amh" && (
          <span className="service-header text-[50px] font-bold max-lg2:text-[40px] text-[#4DB27D]">
            ምስሎች
          </span>
        )}
        {languageState?.lan === "en" && (
          <span className="service-header text-[50px] font-bold max-lg2:text-[40px] text-[#4DB27D]">
            Gallery
          </span>
        )}
      </div>
      <div className="w-[100%] mx-auto flex flex-col gap-[20px]">
        {filterCategory?.length !== 0 && (
          <div className="w-[100%] mx-auto h-[80px]  border-b border-gray-200 flex justify-center items-center overflow-x-auto hide-scroll-bar">
            <div className="mx-auto flex justify-between items-center max-md2:w-[95%]">
              <div className="flex justify-center items-center  max-md2:gap-[50px] text-[14px] text-gray-500">
                <div className="flex justify-center items-center  max-md2:gap-[50px] text-[16px] text-gray-500">
                  {filterCategory?.map((category, index) => {
                    return (
                      <button
                        key={index}
                        className={
                          categoryState?.category === category?._id
                            ? "text-[18px] font-bold py-2 px-4 my-[20px] bg-[#4DB27D] rounded-[20px] capitalize text-white max-md1:text-[16px] whitespace-nowrap"
                            : "text-[18px] py-2 px-4 my-[20px] max-md1:text-[16px] whitespace-nowrap capitalize"
                        }
                        onClick={() =>
                          dispatch(categoryAction.setCategory(category?._id))
                        }
                      >
                        {languageState?.lan === "en" && category?.name_en}
                        {languageState?.lan === "amh" && category?.name_am}
                      </button>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        )}

        <GalleryList category={categoryState?.category} />
      </div>
    </div>
  );
}

export default Gallery;
