import tototIntro from "../Video/banner-vid-3.mp4";
import { useSelector } from "react-redux";

function Banner() {
  const languageState = useSelector((state) => state?.lan);

  return (
    <div className="w-[100%] h-[100vh] max-lg:h-[80vh] max-md2:h-[70vh]">
      <div className="w-[100%] h-[100%] relative">
        <div className="w-[100%] h-[100%] absolute top-0 left-0 z-[-1] bg-black opacity-15" />
        <video
          loop
          muted
          autoPlay
          className="w-[100%] h-[100%] object-cover absolute top-0 left-0 z-[-2]"
        >
          <source src={tototIntro}></source>
        </video>

        <div className="w-[85%] absolute left-[10%] top-[200px] h-[50%] mx-auto flex flex-col justify-end gap-[10px] max-md1:relative max-md1:left-0 max-md1:top-[120px] max-md2:items-center max-sm1:top-[150px] max-sm2:w-[98%] max-sm2:top-[120px]">
          <span
           
            className="text-[24px]  text-gray-300 max-lg1:text-[22px] max-lg:text-[20px] max-md2:text-[18px] max-sm2:text-[16px] max-md2:font-medium"
          >
            {languageState.lan === "en" ? "Since 2009 G.C." : "ከ ፳፻፩ ዓ.ም ጀምሮ"}
          </span>
          <div
            variants={{
              hidden: { opacity: 0, y: 75 },
              show: { opacity: 1, y: 0 },
            }}
            initial="hidden"
            animate="show"
            transition={{ duration: 0.5, delay: 0.5 }}
            className="text-[70px] uppercase flex gap-[10px] items-center max-lg2:text-[50px] max-lg1:text-[30px] max-md1:text-[24px] max-sm1:text-[20px] font-semibold"
          >
            <span className="text-white">
              {languageState?.lan === "en" ? (
                "Totot"
              ) : (
                <span className="max-lg1:text-[40px] max-sm1:text-[30px] max-sm2:text-[24px]">
                  ቶቶት
                </span>
              )}
            </span>
            <span className="text-[#4DB27D]">
              {languageState.lan === "en" ? (
                "TRADITIONAL RESTAURANT"
              ) : (
                <span className="max-lg1:text-[40px] max-sm1:text-[30px] max-sm2:text-[24px]">
                  የባህል ምግብ አዳራሽ
                </span>
              )}
            </span>
          </div>
          <span
            variants={{
              hidden: { opacity: 0, y: 75 },
              show: { opacity: 1, y: 0 },
            }}
            initial="hidden"
            animate="show"
            transition={{ duration: 0.5, delay: 0.8 }}
            className="text-gray-300 text-[32px] font-semibold max-lg1:text-[22px] max-lg:text-[20px] max-md2:text-[18px] max-sm2:text-[16px] max-md2:text-center"
          >
            {languageState?.lan === "en"
              ? " “We serve with excellence”"
              : "“በጥራት እናገለግላለን”"}
          </span>
        </div>
      </div>
    </div>
  );
}

export default Banner;
