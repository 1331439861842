export const generateRandomNumber = () => {
  return Math.floor(Math.random() * 2) + 1;
};

export const truncateText = (text, maxWords) => {
  const words = text.split(" ");

  if (words.length <= maxWords) {
    return text;
  } else {
    const truncatedWords = words.slice(0, maxWords).join(" ");
    return truncatedWords + "...";
  }
};
