import {
  FaFacebook,
  FaInstagram,
  FaPhoneAlt,
  FaTiktok,
  FaYoutube,
} from "react-icons/fa";

import { BiMenuAltLeft } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { navbarActions } from "../REDUX/slices/ResponsiveNavbarSlice";
import { tranlationAction } from "../REDUX/slices/pageTranslationSlice";

export default function TopNavBar() {
  const dispatch = useDispatch();
  const navbarState = useSelector((state) => state.navbar);

  return (
    <div className="w-[100%] h-[50px] py-2 bg-[#0E0E0E] border-b border-gray-800">
      <div className="w-[90%] h-[100%] mx-auto flex justify-between items-center">
        <div className="flex items-center gap-[20px]">
          <BiMenuAltLeft
            onClick={() =>
              dispatch(navbarActions.toggleNavbar(navbarState.isOpened))
            }
            className="text-white text-[24px] cursor-pointer hidden max-md2:block"
          />
          <div className="flex items-center gap-[10px] text-[#4DB27D] max-[1500px]:text-[12px]">
            <FaPhoneAlt className="text-white" />
            <span className="text-[18px] text-white max-lg2:text-[12px]">
              +251 930 012 250 
            </span>
          </div>
        </div>

        <div className="flex items-center gap-[30px] max-[700px]:gap-[10px]">
          <select
            onChange={(e) =>
              dispatch(tranlationAction.setLanguage(e.target.value))
            }
            className="border border-gray-400 rounded-[5px] bg-transparent py-2 px-4 text-white text-[12px] outline-none"
          >
            <option className="text-black" value={"en"}>
              en
            </option>
            <option className="text-black" value={"amh"}>
              አማ
            </option>
          </select>
          <div className="flex items-center gap-[20px] max-md2:hidden">
            <button
              onClick={() =>
                window.open(
                  "https://www.facebook.com/TototTraditionalRestaurant/",
                  "_blank"
                )
              }
            >
              <FaFacebook className="text-white" />
            </button>
            <button
              onClick={() =>
                window.open(
                  "https://www.tiktok.com/@totottraditional",
                  "_blank"
                )
              }
            >
              <FaTiktok className="text-[12px] text-white" />
            </button>
            <button
              onClick={() =>
                window.open(
                  "https://www.instagram.com/totottraditionalresturant/",
                  "_blank"
                )
              }
            >
              <FaInstagram className="text-white" />
            </button>
            <button
              onClick={() =>
                window.open(
                  "https://www.youtube.com/@totottraditionalrestaurant",
                  "_blank"
                )
              }
            >
              <FaYoutube className="text-white" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
