import React from "react";
import EventList from "../components/EventList";
import { useSelector } from "react-redux";
function Events() {
  const languageState = useSelector((state) => state.lan);
  return (
    <div className="w-[100%] my-[90px] p-2">
      <div className="w-[100%] my-[30px] py-3 max-lg2:py-2 flex justify-center items-center  border-b border-gray-200">
        {languageState?.lan === "amh" && (
          <span className="service-header text-[50px] font-bold max-lg2:text-[40px] text-[#4DB27D]">
            ክስተቶች
          </span>
        )}
        {languageState?.lan === "en" && (
          <span className="service-header text-[50px] font-bold max-lg2:text-[40px] text-[#4DB27D]">
            Events
          </span>
        )}
      </div>
      <EventList />
    </div>
  );
}

export default Events;
