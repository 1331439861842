import React from "react";

function Error({ name, height }) {
  return (
    <div
      className={`w-[100%]  my-[100px] min-h-[${height}] flex justify-center items-center`}
    >
      <span className="text-[30px] text-[#4DB27D] font-bold service-header">Failed to load {name}.</span>
    </div>
  );
}

export default Error;
