import React from "react";
import { TbTargetArrow } from "react-icons/tb";
import { useSelector } from "react-redux";
import { FaEye } from "react-icons/fa";
import { FaArrowRight } from "react-icons/fa6";

function About() {
  const languageState = useSelector((state) => state.lan);

  return (
    <div className="w-[100%] p-2 mt-[120px] bg-white">
      <div className="w-[80%] mt-[50px] mx-auto flex flex-col gap-[20px] max-lg1:w-[90%]">
        <div className="w-[100%]">
          <span className="service-header font-bold text-[50px] text-[#4DB27D] max-lg2:text-[40px]">
            {languageState?.lan === "en" ? "About Us" : "ስለ እኛ"}
          </span>
        </div>
        <div className="w-[100%] flex justify-between items-start gap-[10px] max-lg1:flex-col">
          {languageState?.lan === "en" ? (
            <div className="w-[50%] flex flex-col gap-[20px] text-[20px] roboto max-lg2:text-[18px] text-gray-500 max-lg1:w-[100%]">
              <p className="leading-[30px]">
                Welcome to Totot Cultural Food Hall
              </p>
              <p
                variants={{
                  hidden: { opacity: 0, y: 75 },
                  show: { opacity: 1, y: 0 },
                }}
                animate="show"
                transition={{ duration: 0.5, delay: 0.6 }}
                className=" leading-[30px]"
              >
                This is a brief history of a traditional food hall where the
                wonderful imprint of Ethiopianism is printed. Its location is
                called Totot Cultural Food Hall, which is located in front of
                Anbesa Garage. The word "Totot" is derived from the Gurage
                language. The meaning is that together, through work, hard work,
                we will grow and rise.
              </p>

              <p className="leading-[30px]">
                Founded in 2009, Totot had the capacity to accommodate 300
                hundred guests in one cottage, and Totot today is as tall as a
                cedar and expanded as a warka. The main hall which accommodates
                2500 guests with the Totot cultural band, the VIP hall on the
                1st floor which accommodates 350 guests and the Elfne, which is
                designed to accommodate 200 guests, continues its journey today
                by expanding its horizons with the Jazz Marie Night. As soon as
                you arrive, you are mesmerized by the architecture that you see
                from the outside, and when you go inside, Totot is a center of
                delicacies, a store of heritage and identity, a refresher and
                enhancement of personality and psychology, and a place of art
                and craft.
              </p>
            </div>
          ) : (
            <div className="w-[50%] flex flex-col gap-[20px] text-[20px] max-lg2:text-[18px] text-gray-500 max-lg1:w-[100%]">
              <p>እንኳን ወደ ቶቶት የባህል ምግብ አዳራሽ በሰላም መጡ!</p>
              <p className="leading-[35px]">
                ይህ የኢትዮጰያዊነት ድንቅ አሻራ የታተመበት የአንድ ባህላዊ ምግብ አዳራሽ አጭር ታሪክ ነው ።
                መገኛውን ገርጅ መብራት ሃይል ከ አንበሳ ጋራጅ ፊት ለፊት ያደረገው ቶቶት የባህል ምግብ አዳራሽ
                ይባላል መጠርያው ስሙ ። ቶቶት ስርዎ ቃሉ ከጉራጊኛ ቋንቋ የተመዘዘ ነው። ትርጉሙም በጋራ ፤በስራ ፣
                በትጋት እናድጋለን ፣ ከፍ እንላለን ማለት ነው ። በ2001 ዓመተ ምህረት የተመሰረተው ቶቶት  በአንድ
                ጎጆ ቤት  300 መቶ እንግዶችን  የማስተናገድ አቅም የነበረው ሲሆን  ቶቶት ዛሬም እንደ ዝግባ
                እየረዘመ እንደ ዋርካ እየሰፋ 2500 እንግዶችን የሚያስተናግደው ዋና አዳራሽ ከቶቶት የባህል ባንድ
                ጋር፣ 350 እንግዶችን የሚያስተናግደው  1ፎቅ  ላይ የሚገኛው ቨ.አይ.ፕ አዳራሽ እንዲሁም 200
                እንግዶችን እንዲያስተናግድ ተደርጎ የተሰራው እልፍኝ ከጃዝ ማሪ ምሽት ጋር  አድማሱን በማስፋት ዛሬም
                ጉዞውን ቀጥሏል ። ቶቶት ብዙ ነው ፡፡ እንደደረሱ ከውጭ በሚመለከቱት እርትቴክት  እየተደመሙ  ወደ
                 ውስጥ ሲገቡ ፤የጣፋጭ ምግቦች ማዕከል፣ የቅርስ እና የማንነት አሻራዎች መደብር ፣ የስብዕና እና
                የስነ ልቦና ማደሻ እና ማበልጸጊያ ፣ የኪነ ጥበብ እና የስነ ጥበብ አውዳማ ነው ቶቶት ።
              </p>
            </div>
          )}

          <div className="w-[40%] grid grid-cols-3 gap-4 max-lg1:w-[100%]  max-sm1:my-[30px]">
            <div className="col-span-2 max-sm1:col-span-3">
              <img
                src={require(`../Images/1.jpg`)}
                className="w-[100%] h-[250px] rounded-[5px]  object-cover"
                alt={""}
              />
            </div>
            <div className="col-span-1  max-sm1:col-span-3">
              <img
                src={require(`../Images/band-1.jpg`)}
                className="w-[100%] h-[250px] rounded-[5px]  object-cover"
                alt={""}
              />
            </div>
            <div className="col-span-1  max-sm1:col-span-3">
              <img
                src={require(`../Images/menu-27.jpg`)}
                className="w-[100%] h-[250px] rounded-[5px]  object-cover"
                alt={""}
              />
            </div>
            <div className="col-span-2  max-sm1:col-span-3">
              <img
                src={require(`../Images/view-2.1.jpg`)}
                className="w-[100%] h-[250px] rounded-[5px]  object-cover"
                alt={""}
              />
            </div>
          </div>
        </div>

        <div className="w-[100%] min-h-[80vh] flex justify-between items-center max-md2:flex-col max-md2:justify-around max-md2:gap-10">
          <div className="w-[40%] flex flex-col items-center gap-[20px] max-md2:w-[90%] max-md2:mx-auto">
            <TbTargetArrow className="text-[100px] text-[#4DB27D] max-sm1:text-[70px]" />
            <div className="flex flex-col items-center gap-[20px]">
              <span className="text-[24px] font-bold">
                {languageState?.lan === "en" ? "Mission" : "ተልኮ"}
              </span>
              {languageState?.lan === "amh" && (
                <span className="text-[20px] max-lg2:text-[18px] leading-[30px] text-gray-500 text-center">
                  የእኛ ተልእኮ የኢትዮጵያን ባህል ውበት ለሚያውቁት እና አዲስ ለሆኑ ሰዎች ጥራት ያለው አገልግሎት
                  ደንበኛው በሚጠብቀው እና ከዚያ በላይ በሆነ መልኩ ማቅረብ ነው።
                </span>
              )}
              {languageState?.lan === "en" && (
                <p className="text-[20px] max-lg2:text-[18px] leading-[30px] text-gray-500 text-center">
                  Our mission is to portray the beauty of Ethiopian culture at
                  its best to those who already know it and to those who are new
                  to it, by offering quality services based on the customer’s
                  expectations and beyond. And to raise the bar every time.
                </p>
              )}
            </div>
          </div>
          <div className="w-[40%] flex flex-col items-center gap-[20px] max-md2:w-[90%] max-md2:mx-auto">
            <FaEye className="text-[100px] text-[#4DB27D]" />
            <div className="flex flex-col items-center gap-[20px]">
              <span className="text-[24px] font-bold">
                {languageState?.lan === "en" ? "Vision" : "ራእይ"}
              </span>

              {languageState?.lan === "amh" && (
                <p className="text-[20px] max-lg2:text-[18px] leading-[30px] text-gray-500 text-center">
                  ራዕያችን በዚህ ሴክተር ውስጥ ምርጡን ለመሆን ነው ለርስዎ እርካታ ጠንክረው የሚሰሩ ተነሳሽ
                  ሰራተኞች እና ተጨማሪ እና የሚመጡ አገልግሎቶችን ለደንበኞቻችን ለማቅረብ።
                </p>
              )}
              {languageState?.lan === "en" && (
                <p className="text-[20px] max-lg2:text-[18px] leading-[30px] text-gray-500 text-center">
                  Our vision is to become the best in this sector with motivated
                  employees who work hard for your satisfaction; and to deliver
                  more up and coming services for our customers, soon to be
                  revealed.
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="w-[100%] min-h-[80vh]  flex flex-col gap-[50px]">
          <div className="w-[100%] mt-[60px] mb-[30px] flex justify-center items-center">
            {languageState?.lan === "en" && (
              <span className="text-[50px] max-lg2:text-[30px] text-center text-[#4DB27D] font-bold max-sm1:text-[24px] max-md2:text-center service-header">
                Message from Mr. Tenkir Teni, Owner and CEO of Totot Traditional
                Restaurant
              </span>
            )}
            {languageState?.lan === "amh" && (
              <span className="text-[50px] max-lg2:text-[30px] text-center text-[#4DB27D] font-bold max-sm1:text-[24px] max-md2:text-center">
                የቶቶት ባህላዊ ምግብ አዳራሽ ባለቤት እና ዋና ስራ አስኪያጅ ከሆኑት ከአቶ ጠንክር ቴኒ የተላከ
                መልእክት
              </span>
            )}
          </div>
          <div className="w-[100%] gap-5 flex justify-between max-lg:flex-col max-md2:gap-[20px]">
            <div className="w-[45%] max-lg:w-[80%] max-md1:w-[90%] max-lg:mx-auto max-sm2:w-[100%]">
              <iframe
                className="w-[100%] h-[350px] rounded-xl max-lg:h-[400px] max-sm1:h-[300px]"
                src="https://www.youtube.com/embed/-b-B2tWxN7s?si=-PyZa-d76LgDBRlK"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            </div>
            <div className="w-[50%] max-lg:w-[80%] max-md1:w-[90%] max-lg:mx-auto max-sm2:w-[100%]">
              {languageState?.lan === "en" && (
                <p className="text-gray-500 text-[20px] max-lg2:text-[18px] leading-[40px]">
                  “Come experience the authentic taste of Ethiopia at Totot
                  Traditional Restaurant in Addis Ababa! Known for our delicious
                  "kitfo" and warm hospitality, our restaurant also features a
                  traditional band and the famous Ethiopian Coffee Ceremony. Our
                  house has welcomed many celebrities, including Jackie Chan and
                  the robot Sophie, making it a popular choice for both locals
                  and foreigners alike. Join us for a truly unforgettable dining
                  experience!“
                </p>
              )}
              {languageState?.lan === "amh" && (
                <p className="text-gray-500 text-[20px] max-lg2:text-[18px] leading-[40px]">
                  ይምጡ አዲስ አበባ በሚገኘው ቶቶት የባህል ምግብ ቤት እውነተኛውን የኢትዮጵያዊ የምግብ ጣዕምን 
                  ያጣጥሙ! በምርጥ የጉራጌ ክትፎ እና ሞቅ ያለ መስተንግዶ የሚታወቀው ሬስቶራንታችን የባህል ባንድ
                  እና ታዋቂው የኢትዮጵያ ቡና ስርዓትንም ይዟል። ቤታችን ጃኪ ቻንን እና ሮቦት ሶፊን ጨምሮ ብዙ
                  ታዋቂ ሰዎችን ተቀብሏል፣ ይህም ለሀገር ውስጥም ሆነ ለውጭ አገር ሰዎች ተወዳጅ እና ተመራጭ
                  ያደርገዋል። ይቀላቀሉን እውነተኛ እና የማይረሳ ጊዜ አብረውን ያሳልፉ!
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="w-[100%] min-h-[50vh] max-lg2:my-[30px] flex flex-col gap-[10px]">
          <div className="w-[100%] my-[30px] flex justify-between items-center">
            {languageState?.lan === "en" && (
              <span className="text-[50px] max-lg2:text-[30px] text-[#4DB27D] font-bold service-header">
                Explore
              </span>
            )}
            {languageState?.lan === "amh" && (
              <span className="text-[50px] max-lg2:text-[30px] text-[#4DB27D] font-bold service-header">
                ይጎብኙ
              </span>
            )}

            {languageState?.lan === "en" && (
              <button
                className="text-[30px] max-lg2:text-[18px] hover:text-[#4DB27D] hover:underline service-header flex items-center gap-2 cursor-pointer transition duration-300"
                onClick={() =>
                  window.open(
                    "https://www.youtube.com/@totottraditionalrestaurant",
                    "_blank"
                  )
                }
              >
                Visit Our YouTube <FaArrowRight />
              </button>
            )}
            {languageState?.lan === "amh" && (
              <button
                className="text-[30px] max-lg2:text-[18px] hover:text-[#4DB27D] hover:underline service-header flex items-center gap-2 cursor-pointer transition duration-300"
                onClick={() =>
                  window.open(
                    "https://www.youtube.com/@totottraditionalrestaurant",
                    "_blank"
                  )
                }
              >
                የዩቲዩብ ቻናላችንን ይጎብኙ <FaArrowRight />
              </button>
            )}
          </div>
          <div className="w-[100%] gap-5 grid grid-cols-3 max-lg:grid-cols-2 max-md1:grid-cols-1 mb-5">
            <iframe
              className="w-[100%] h-[300px] max-lg2:h-[200px] max-md1:h-[300px] rounded-xl"
              src="https://www.youtube.com/embed/eqI4uAOFNkQ?si=sk-JdbNbpsVsQI9x"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>

            <iframe
              className="w-[100%] h-[300px] max-lg2:h-[200px] max-md1:h-[300px] rounded-xl"
              src="https://www.youtube.com/embed/TWYZa8pXVs8?si=MJ0ueS4yskgLdBhQ"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
            <iframe
              className="w-[100%] h-[300px] max-lg2:h-[200px] max-md1:h-[300px] rounded-xl"
              src="https://www.youtube.com/embed/0sGZ_6n0rJY?si=2frzBMhm1vWtNJvz"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>

            <iframe
              className="w-[100%] h-[300px] max-lg2:h-[200px] max-md1:h-[300px] rounded-xl"
              src="https://www.youtube.com/embed/pvyOeZCPueM?si=UG4lyNRXRhT_D2wG"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>

            <iframe
              className="w-[100%] h-[300px] max-lg2:h-[200px] max-md1:h-[300px] rounded-xl"
              src="https://www.youtube.com/embed/0sGZ_6n0rJY?si=G07IS8pEA_CEYG9K"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>

            <iframe
              className="w-[100%] h-[300px] max-lg2:h-[200px] max-md1:h-[300px] rounded-xl"
              src="https://www.youtube.com/embed/n11PVQ3bL2g?si=6sRZzawqxZlt5nvT"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
