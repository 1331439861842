import React from "react";
import { useSelector } from "react-redux";

function ChooseUs() {
  const languageState = useSelector((state) => state?.lan);

 


  return (
    <div  className="w-[100%] p-2 min-h-[500px]">
      <div className="w-[70%] mx-auto my-[50px] grid grid-cols-6 gap-4 max-lg2:w-[90%] max-lg1:grid-cols-4">
        <div
         
          className="w-[100%] col-span-3 p-2 bg-[#FFFBFB] rounded-[10px] shadow-xl max-lg1:col-span-4"
        >
          <div className="w-[90%] mx-auto flex flex-col gap-[30px]">
            <div className="service-header text-[50px] flex items-center gap-[10px] max-lg2:text-[30px] max-sm1:text-[35px]">
              {languageState?.lan === "en" && <span>Why</span>}
              {languageState?.lan === "en" && (
                <span className="text-[#4DB27D]">Us</span>
              )}
              {languageState?.lan === "amh" && (
                <span className="max-sm1:text-[30px]">ለምን እኛን</span>
              )}
              {languageState?.lan === "amh" && (
                <span className="text-[#4DB27D] max-sm1:text-[30px]">
                  ይመርጡናል
                </span>
              )}
            </div>
            <div className="flex flex-col items-start gap-[20px]">
              {languageState?.lan === "en" && (
                <span className="text-[30px] font-bold roboto max-lg2:text-[24px]  text-[#4DB27D] max-sm1:text-[20px]">
                  Exquisite Ethiopian Cuisine
                </span>
              )}
              {languageState?.lan === "amh" && (
                <span className="text-[30px] font-bold  max-lg2:text-[20px] text-[#4DB27D] max-sm1:text-[20px]">
                  ድንቅ የኢትዮጵያ ምግብ
                </span>
              )}

              {languageState?.lan === "en" && (
                <span className="text-[18px] max-lg2:text-[16px] mb-[20px] leading-[30px] text-gray-500">
                  Experience the true flavors of Ethiopian cuisine at Totot
                  Traditional Restaurant. Our specialty dish, "Kitfo," is
                  renowned for its authentic taste and is a must-try for both
                  locals and tourists. We take pride in serving cultural dishes
                  that showcase the richness of Ethiopian culinary traditions.
                </span>
              )}

              {languageState?.lan === "amh" && (
                <span className="text-[18px] max-lg2:text-[16px] mb-[20px] leading-[30px] text-gray-500">
                  በቶቶት ባህላዊ ምግብ ቤት እውነተኛውን የኢትዮጵያ ምግብ ጣዕም ይለማመዱ። የእኛ ልዩ ምግብ
                  "ክትፎ" በትክክለኛ ጣዕሙ ታዋቂ ነው እናም ለአካባቢው ነዋሪዎች እና ቱሪስቶች መሞከር ያለበት
                  ነው። የኢትዮጵያን የምግብ አሰራር ባህል የሚያሳዩ የባህል ምግቦችን በማቅረብ እንኮራለን።
                </span>
              )}
            </div>
          </div>
        </div>
        <div
          
          className="col-span-2 bg-[#FFFBFB] rounded-[10px] shadow-xl max-md2:col-span-4"
        >
          <div className="w-[90%] mt-[30px] mx-auto">
            <div className="flex flex-col items-start gap-[20px]">
              {languageState?.lan === "en" && (
                <span className="text-[30px] font-bold roboto max-lg2:text-[20px] text-[#4DB27D] max-sm1:text-[20px]">
                  Captivating Dining Experience
                </span>
              )}
              {languageState?.lan === "amh" && (
                <span className="text-[30px] font-bold  max-lg2:text-[20px] text-[#4DB27D] max-sm1:text-[20px]">
                  የሚስብ የመመገቢያ ልምድ
                </span>
              )}

              {languageState?.lan === "en" && (
                <span className="text-[18px] max-lg2:text-[16px] mb-[20px] leading-[30px] text-gray-500">
                  Prepare to be mesmerized by our live band and traditional
                  dancers. Our performers create an engaging and lively
                  atmosphere, allowing you to immerse yourself in the vibrant
                  Ethiopian culture. Enjoy an unforgettable dining experience
                  filled with music, dance, and entertainment.
                </span>
              )}

              {languageState?.lan === "amh" && (
                <span className="text-[16px] max-lg2:text-[16px] mb-[20px] leading-[30px] text-gray-500">
                  በእኛ የቀጥታ ባንድ እና በባህላዊ ለመማር ተዘጋጅ ዳንሰኞች. የእኛ ፈጻሚዎች አሳታፊ እና ሕያው
                  ይፈጥራሉ ከባቢ አየር ፣ እራስዎን በነቃ ውስጥ እንዲጠመቁ ያስችልዎታል የኢትዮጵያ ባህል። የማይረሳ
                  የመመገቢያ ተሞክሮ ይደሰቱ በሙዚቃ፣ በዳንስ እና በመዝናኛ የተሞላ።
                </span>
              )}
            </div>
          </div>
        </div>
        <div
          
          className="col-span-1 row-span-2 bg-[#FFFBFB] rounded-[10px] shadow-xl max-[1100px]:row-span-1 max-lg1:col-span-2 max-md2:col-span-4"
        >
          <div className="w-[90%] mt-[30px] mx-auto">
            <div className="flex flex-col items-start gap-[20px]">
              {languageState?.lan === "en" && (
                <span className="text-[30px] font-bold roboto max-lg2:text-[20px] text-[#4DB27D] max-sm1:text-[20px]">
                  Beautifully Designed Interior and Exterior
                </span>
              )}
              {languageState?.lan === "amh" && (
                <span className="text-[30px] font-bold  max-lg2:text-[20px] py-4 text-[#4DB27D] max-sm1:text-[20px]">
                  በሚያምር ሁኔታ የተሰራ የውስጥ እና የውጪ ዲዛይን
                </span>
              )}
              {languageState?.lan === "en" && (
                <span className="text-[18px] max-lg2:text-[16px] mb-[20px] leading-[30px] text-gray-500">
                  Step into a world where tradition meets elegance. Our
                  restaurant boasts a meticulously designed interior and
                  exterior that exude Ethiopian charm. Every detail, from the
                  decor to the equipment, reflects our commitment to preserving
                  and celebrating Ethiopian customs. Immerse yourself in a
                  visually stunning setting that enhances your dining
                  experience.
                </span>
              )}
              {languageState?.lan === "amh" && (
                <span className="text-[18px] max-lg2:text-[16px] mb-[20px] leading-[30px] text-gray-500">
                  ባህል እና ውበት ወደ ሚያሟላበት ዓለም ግቡ። ሬስቶራንታችን ኢትዮጲያዊ ውበትን የሚያጎናፅፍ የውስጥ
                  እና የውጪ ዲዛይን አለው። እያንዳንዱ ዝርዝር ሁኔታ ከዲኮር እስከ መሳሪያዎቹ የኢትዮጵያን ባህል
                  ለመጠበቅ እና ለማክበር ያለንን ቁርጠኝነት ያሳያል። የመመገቢያ ልምድዎን በሚያሻሽል በሚታይ
                  አስደናቂ ቅንብር ውስጥ እራስዎን ያስገቡ።
                </span>
              )}
            </div>
          </div>
        </div>
        <div
         
          className="col-span-2  bg-[#FFFBFB] rounded-[10px] shadow-xl max-md2:col-span-4"
        >
          <div className="w-[90%] mt-[30px] mx-auto">
            <div className="flex flex-col items-start gap-[20px]">
              {languageState?.lan === "en" && (
                <span className="text-[30px] font-bold roboto max-lg2:text-[20px] text-[#4DB27D] max-sm1:text-[20px]">
                  Dedicated to Customer Satisfaction
                </span>
              )}
              {languageState?.lan === "amh" && (
                <span className="text-[30px] font-bold  max-lg2:text-[20px] text-[#4DB27D] max-sm1:text-[20px]">
                  ለደንበኛ እርካታ የተሰጠ
                </span>
              )}
              {languageState?.lan === "en" && (
                <span className="text-[18px] max-lg2:text-[16px] mb-[20px] leading-[30px] text-gray-500">
                  At Totot Traditional Restaurant, our mission is to exceed
                  customer expectations. We strive to provide quality services
                  that go above and beyond. From attentive and friendly staff to
                  a warm and welcoming ambiance, we are committed to making your
                  stay with us as wonderful as possible. Your satisfaction is
                  our top priority.
                </span>
              )}
              {languageState?.lan === "amh" && (
                <span className="text-[18px] max-lg2:text-[16px] leading-[30px] text-gray-500">
                  በቶቶት ባህላዊ ምግብ ቤት፣ ተልእኳችን ከደንበኞች ከሚጠበቀው በላይ ማድረግ ነው። ጥራት ያለው
                  አገልግሎት ለማቅረብ እንጥራለን። በትኩረት እና ተግባቢ ከሆኑ ሰራተኞች እስከ ሞቅ ያለ እና
                  የእንኳን ደህና መጣችሁ ድባብ፣ ከእኛ ጋር ያለዎትን ቆይታ በተቻለ መጠን አስደናቂ ለማድረግ
                  ቁርጠናል። የእርስዎ እርካታ የእኛ ቅድሚያ የሚሰጠው ጉዳይ ነው።
                </span>
              )}
            </div>
          </div>
        </div>
        <div
        
          className="col-span-3 bg-[#FFFBFB] rounded-[10px] shadow-xl max-lg1:col-span-2 max-md2:col-span-4"
        >
          <div className="w-[90%] mt-[30px] mx-auto">
            <div className="flex flex-col items-start gap-[20px]">
              {languageState?.lan === "en" && (
                <span className="text-[30px] font-bold roboto max-lg2:text-[20px] text-[#4DB27D] max-sm1:text-[20px]">
                  Local Drinks Crafted with Expertise
                </span>
              )}

              {languageState?.lan === "amh" && (
                <span className="text-[30px] font-bold roboto max-lg2:text-[20px] text-[#4DB27D] max-sm1:text-[20px]">
                  በባለሙያ የተሰሩ የአካባቢ መጠጦች
                </span>
              )}

              {languageState?.lan === "en" && (
                <span className="text-[18px] max-lg2:text-[16px] mb-[20px] leading-[30px] text-gray-500">
                  Indulge in the flavors of our locally crafted drinks. Our
                  professional chefs skillfully prepare traditional beverages
                  like "Tej" and "Areqe," as well as gin. These drinks are
                  expertly paired with our nourishing dishes, enhancing the
                  overall taste and providing a truly authentic Ethiopian dining
                  experience.
                </span>
              )}
              {languageState?.lan === "amh" && (
                <span className="text-[18px] max-lg2:text-[16px] mb-[20px] leading-[30px] text-gray-500">
                  በአገር ውስጥ በተዘጋጁት መጠጦች ጣዕሞች ውስጥ ይግቡ። የእኛ ፕሮፌሽናል ሼፎች እንደ "ቴጅ" እና
                  "አረቄ" እንዲሁም ጂን የመሳሰሉ ባህላዊ መጠጦችን በብቃት ያዘጋጃሉ። እነዚህ መጠጦች በባለሙያ
                  ከተመጣጣኝ ምግቦቻችን ጋር የተጣመሩ፣ አጠቃላይ ጣዕሙን የሚያሳድጉ እና እውነተኛ ኢትዮጵያዊ
                  የመመገቢያ ልምድን ይሰጣሉ።
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChooseUs;
