import React from "react";
import { FaLocationDot, FaPhone } from "react-icons/fa6";
import { IoTimeSharp } from "react-icons/io5";
import { BiCalendarWeek } from "react-icons/bi";
import { useSelector } from "react-redux";
import { MdEmail } from "react-icons/md";

function Footer() {
  const languageState = useSelector((state) => state?.lan);
  return (
    <div className=" w-[100%] min-h-[300px]  p-2 bg-black">
      <div className="w-[80%] mx-auto my-[20px]   max-lg2:w-[90%]">
        <div className="w-[100%] mt-[50px] flex justify-between  gap-[30px] max-lg:flex-col max-md1:items-start max-md1:gap-[20px]">
          <div className="w-[50%] flex flex-col gap-[20px] text-white  max-lg:w-[100%]">
            {languageState?.lan === "amh" && (
              <span className="roboto font-bold text-[40px]  max-md1:text-[24px]">
                ስለ እኛ
              </span>
            )}
            {languageState?.lan === "en" && (
              <span className="roboto font-bold text-[40px]  max-md1:text-[24px]">
                About Us
              </span>
            )}

            {languageState?.lan === "en" && (
              <span className="leading-[30px] text-[16px] max-lg2:text-[14px] text-gray-300 max-lg:w-[90%] max-md2:text-[14px] max-md1:w-[100%]">
                Totot is a traditional restaurant which is established in 2001
                E.C.and the word "Totot" is derived from Gurage language which
                means "lets work".
              </span>
            )}

            {languageState?.lan === "amh" && (
              <span className="leading-[30px] text-[14px] text-gray-300 max-lg:w-[90%] max-md2:text-[14px] max-md1:w-[100%]">
                ቶቶት በ2001 ዓ.ም የተመሰረተ ባህላዊ ሬስቶራንት ሲሆን "ቶቶት" የሚለው ቃል ከጉራጌ ቋንቋ የተገኘ
                ሲሆን ትርጉሙም ጠንክረን እንስራ ማለት ነው።
              </span>
            )}
          </div>
          <div className="w-[30%] flex flex-col gap-[20px] text-white max-lg:w-[100%]">
            {languageState?.lan === "amh" && (
              <span className="service-header text-[20px]">አድራሻ / ስልክ</span>
            )}
            {languageState?.lan === "en" && (
              <span className="roboto font-bold text-[20px]">Contact</span>
            )}

            <div className="flex items-center gap-[10px]">
              <FaLocationDot />
              {languageState?.lan === "amh" && (
                <span className="text-[16px] max-lg2:text-[14px] text-gray-300 max-md2:text-[14px]">
                  ገርጂ መብራት ህይል መንገድ ኔክሰስ ሆቴል ፊት ለፊት
                </span>
              )}
              {languageState?.lan === "en" && (
                <span className="text-[16px] max-lg2:text-[14px] text-gray-300 max-md2:text-[14px]">
                  Gerji Mebrat Haile Road Infront of Nexus Hotel
                </span>
              )}
            </div>
            <div className="flex items-center gap-[10px] ">
              <FaPhone />
              <span className="text-[16px] max-lg2:text-[14px] text-gray-300 max-md2:text-[14px]">
                +251 909 004 400 / +251 930 012 251
              </span>
            </div>
            <div className="flex items-center gap-[10px] ">
              <FaPhone />
              <span className="text-[16px] max-lg2:text-[14px] text-gray-300 max-md2:text-[14px]">
                +251 116 460 718
              </span>
            </div>
            <div className="flex items-center gap-[10px] ">
              <MdEmail />
              <span className="text-[16px] max-lg2:text-[14px] text-gray-300 max-md2:text-[14px]">
                totottraditionalresturant@gmail.com
              </span>
            </div>
          </div>
          <div className="w-[30%] flex flex-col gap-[20px] text-white max-lg:w-[100%]">
            {languageState?.lan === "en" && (
              <span className="roboto font-bold text-[20px]">
                Working Hours
              </span>
            )}
            {languageState?.lan === "amh" && <span>የስራ ሰዓት</span>}
            <div className="flex items-center gap-[10px]">
              <BiCalendarWeek />
              {languageState?.lan === "en" && (
                <span className="text-[16px] max-lg2:text-[14px] text-gray-300 max-md2:text-[14px]">
                  Monday - Sunday
                </span>
              )}
              {languageState?.lan === "amh" && (
                <span className="text-[16px] max-lg2:text-[14px] text-gray-300 max-md2:text-[14px]">
                  ከሰኞ - እሁድ
                </span>
              )}
            </div>
            <div className="flex items-center gap-[10px]">
              <IoTimeSharp />
              {languageState?.lan === "amh" && (
                <span className="text-[16px] text-gray-300 max-dm2:text-[14px]">
                  ከጠዋቱ 2፡00 ሰዓት እስከ ሌሊት 6፡00
                </span>
              )}

              {languageState?.lan === "en" && (
                <span className="text-[16px] text-gray-300 max-md2:text-[14px]">
                  8:00 AM to Midnight 12:00 PM, with exceptions to 2:00 AM.
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="w-[100%] my-[20px] border-t border-gray-500 py-6 text-white text-center">
          <span className="text-[12px]">
            &copy; 2024 TOTOT TRADITIONAL RESTAURANT. All right reserved. <a href="https://excelconsultrade.com" target="_blank" className="hover:underline" rel="noreferrer">Powered by Excel Consultrade</a>
          </span>
        </div>
      </div>
    </div>
  );
}

export default Footer;
