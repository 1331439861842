import { FaArrowRight } from "react-icons/fa6";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { truncateText } from "../util/helper";
import { fetchEvents } from "../REDUX/slices/eventSlice";
import Loading from "./Loading";

function LatestEvents() {
  const dispatch = useDispatch();
  const eventState = useSelector((state) => state.events);
  const languageState = useSelector((state) => state.lan);

  useEffect(() => {
    dispatch(fetchEvents({ page: 1, sort: -1, title: "" }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  const filterEvents = eventState?.events?.events?.filter(
    (event) => event?.status === "active"
  );

  return (
    <div  className="w-[100%] p-2 min-h-[600px]">
      <div className="w-[80%] mt-[50px] mx-auto flex flex-col gap-[20px] max-lg2:w-[90%]">
        <div
        
          className="flex justify-between items-center"
        >
          {languageState?.lan === "en" && (
            <div className="flex items-center gap-[10px] service-header text-[50px] max-lg2:text-[30px]">
              <span>Latest</span>
              <span className=" text-[#4DB27D]">Events</span>
            </div>
          )}

          {languageState?.lan === "amh" && (
            <div className="flex items-center gap-[10px] service-header text-[50px] max-lg2:text-[30px] max-sm1:text-[24px]">
              <span>የቅርብ</span>
              <span className=" text-[#4DB27D]">ክስተቶች</span>
            </div>
          )}

          <a
            href="/events"
            className="flex items-center gap-[10px] hover:text-[#4DB27D]"
          >
            {languageState?.lan === "en" && (
              <span className="font-bold service-header text-[20px] max-lg2:text-[18px] max-sm1:text-[18px]">
                See more
              </span>
            )}
            {languageState?.lan === "amh" && (
              <span className="font-bold service-header">ሁሉንም ይመልከቱ</span>
            )}

            <FaArrowRight />
          </a>
        </div>

        <div className="w-[90%] mt-[50px] mx-auto grid grid-cols-2 gap-[20px] max-lg2:w-[100%]">
          {eventState?.loading && filterEvents?.length === 0 ? (
            <Loading
              addtionalStyle={
                "w-[100%] my-[20px] flex justify-center items-center"
              }
            />
          ) : (
            filterEvents?.slice(0, 2)?.map((le, index) => {
              return (
                <div
                 
                  className="w-[100%] col-span-1  flex-col flex bg-white  p-2 rounded-[10px] max-md2:col-span-2 max-md2:w-[100%] max-lg:flex-col"
                  key={index}
                >
                  <div className="w-[100%] my-[20px] mx-auto">
                    <img
                      src={`${process.env.REACT_APP_BACKEND_IMAGE_URL}/EventImages/${le?.thumbnail}`}
                      className="w-[100%] max-h-[330px] mx-auto  rounded-[5px]  object-cover pointer-events-none max-md1:w-[100%]"
                      alt={""}
                    />
                  </div>
                  <div className="w-[100%] mx-auto flex flex-col gap-[5px] items-start py-2">
                    {languageState?.lan === "en" && (
                      <span className="roboto capitalize font-semibold text-[24px] max-lg2:text-[18px] text-[#4DB27D]">
                        {le?.title_en}
                      </span>
                    )}
                    {languageState?.lan === "amh" && (
                      <span className="service-header font-semibold text-[24px] max-lg2:text-[20px] text-[#4DB27D]">
                        {le?.title_am}
                      </span>
                    )}

                    {languageState?.lan === "en" && (
                      <span className="text-gray-500 w-[100%] text-[18px] max-lg2:text-[16px] leading-[30px]">
                        {truncateText(le?.description_en, 18)}
                      </span>
                    )}

                    {languageState?.lan === "amh" && (
                      <span className="text-gray-500 text-[18px] leading-[30px]">
                        {truncateText(le?.description_am, 15)}
                      </span>
                    )}
                    <div className="my-[20px]">
                      {languageState?.lan === "en" && (
                        <a
                          href={`/events/more_info/${le?._id}`}
                          className="text-white bg-[#4DB27D] font-bold px-4 py-2 rounded-[5px] text-[16px]"
                        >
                          Read more
                        </a>
                      )}
                      {languageState?.lan === "amh" && (
                        <a
                          href={`/events/more_info/${le?._id}`}
                          className="text-white bg-[#4DB27D] px-4 py-2 rounded-[5px] text-[16px]"
                        >
                          ተጨማሪ ያንብቡ
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              );
            })
          )}
        </div>
      </div>
    </div>
  );
}

export default LatestEvents;
